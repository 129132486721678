import {Alert, Box, Collapse} from "@mui/material";
import TeamMemberCollapsable from "../components/TeamMemberCollapsable";
import React, {useEffect, useState} from "react";
import AddTeamMemberFormComponent from "../components/Form Components/AddTeamMemberFormComponent";
import {useSelector} from "react-redux";
import {selectCurrentProject, selectTeamMembers} from "../features/states/ProjectFunctions";
import {getMaxTeamMembers} from "../functions/ValidationFunctions";
import {GetTeamInfoFromServer} from "../functions/ServerCallFunctions";

function TeamListDisplayPage({projectID}) {
    const [messageAlertOpen, setMessageAlertOpen] = useState(false);

    const teamMembers = useSelector(selectTeamMembers)
    const currentProject = useSelector(selectCurrentProject)


    function hasProjectReachedLimit() {
        return teamMembers.length >= getMaxTeamMembers();
    }

    let id = projectID;

    useEffect(() => {
        UpdateTeamList()
    }, [])

    async function UpdateTeamList(){
        await GetTeamInfoFromServer(id)
    }

    const isAdmin = currentProject.isAdmin || currentProject.isOwner;


    function TeamMemberList() {
         if (teamMembers !== undefined) {
            return <Box sx={{display: `flex`, flexDirection: `column`}}>
                {teamMembers.map((item, index) => {
                    return <TeamMemberCollapsable
                        key={item.userId}
                        item={item}
                        index={index}
                        isAdmin={isAdmin}
                        projectId={id}
                    />
                })}
            </Box>
        }
    }



    return (
        <div>
            {isAdmin ? <AddTeamMemberFormComponent projectId={id} disabled={hasProjectReachedLimit()}/> : null}
            <TeamMemberList/>
            <Collapse in={messageAlertOpen}>
                <Alert severity="success" sx={{mt: 2}} onClose={() => setMessageAlertOpen(false)}>
                    Team Members Saved!
                </Alert>
            </Collapse>
        </div>
    )
}

export default TeamListDisplayPage;