import {useDispatch, useSelector} from "react-redux";
import {
    selectCurrentView,
    selectProjectNavigationIndex,
    selectUnity,
    setPrimaryNavigationIndex,
    setProjectNavigationIndex
} from "../../features/states/ProjectFunctions";
import React from "react";
import {Badge, Box, Tab} from "@mui/material";
import {Link as RouterLink, useLocation} from "react-router-dom";
import {AnimotiveTab, AnimotiveTabs} from "../StyledComponents/StyledComponents";
import {OpenAssetConfig} from "../Structural Components/UnityCommandsContext";

export function PrimaryNavTabs() {
    const tabValue = useSelector(SetDefaultTab)
    const indicatorDisplay = useSelector(selectCurrentView) !== "Account Settings"
    const dispatch = useDispatch();

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        dispatch(setPrimaryNavigationIndex(newValue));
    };

    function SetIndicatorToDisplay() {
        if (indicatorDisplay) {
            return "block"
        } else {
            return "none"
        }
    }

    function SetDefaultTab() {
        const location = useLocation();
        if (location.pathname === "/projects") {
            return 1
        } else if (location.pathname === "/invitations") {
            return 2
        } else {
            return 0
        }
    }

    function a11yProps(index: number) {
        return {
            id: `tab-${index}`, 'aria-controls': `tabpanel-${index}`,
        };
    }

    function TabItem({label, index, ...props}) {

        const count = useSelector(state => state.invitesList.count)
        return <Badge anchorOrigin={{vertical: `top`, horizontal: "right"}} badgeContent={count} color="error"
                      sx={{"& .MuiBadge-badge": {marginTop: "10px", marginRight: "10px"}}}>
            <AnimotiveTab label={label} {...a11yProps(index)} component={RouterLink} to={"/"} {...props}/>
        </Badge>
    }

    return (<Box sx={{justifyContent: `center`, flexGrow: 1}}>
        <AnimotiveTabs indicator={SetIndicatorToDisplay()} value={tabValue} onChange={handleTabChange}>
            <AnimotiveTab label="GET STARTED" component={RouterLink} to={"/"} value={0}/>
            <AnimotiveTab label="PROJECTS" component={RouterLink} to={"/projects"} value={1}/>
            <TabItem label={"INVITATIONS"} component={RouterLink} to={"/invitations"} value={2}/>
        </AnimotiveTabs>
    </Box>);
}

export function ProjectNavTabs() {

    const tabValue = useSelector(selectProjectNavigationIndex)
    const isUnity = useSelector(selectUnity)
    const dispatch = useDispatch();

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue === 2) {
            if (isUnity) {
                OpenAssetConfig()
            }
        } else {
            dispatch(setProjectNavigationIndex(newValue));
        }
    };

    function a11yProps(index: number) {
        return {
            id: `tab-${index}`, 'aria-controls': `tabpanel-${index}`,
        };
    }

    return (<Box sx={{justifyContent: `center`, flexGrow: 1}}>
        <AnimotiveTabs value={tabValue} onChange={handleTabChange} sx={{justifyContent: "space-between"}}>
            <Tab label="SCENES" tabIndex={0} {...a11yProps(0)} />
            <Tab label="TEAM" tabIndex={1}{...a11yProps(1)} />
            <Tab sx={{display: isUnity ? "inline-flex" : "none"}} label="ASSET LIBRARY"
                 tabIndex={2} {...a11yProps(2)} />
        </AnimotiveTabs>
    </Box>);
}