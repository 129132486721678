import {getTokenValueFromAWS} from "../functions/AmplifyFunctions";

export default async function DeleteAPI(params,route) {

    let requestOptions;
    await getTokenValueFromAWS().then((token) => {
        requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + token,
                'accept': 'application/json',
            },
            body: JSON.stringify(params),
        };
    });
    let baseUrl;

    if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        baseUrl = process.env.REACT_APP_DEV_ENDPOINT_BASE_URL;
    } else {
        baseUrl = process.env.REACT_APP_ENDPOINT_BASE_URL;
    }
    const checkRouteOrQueryStringValidity = (route) => {
        return route !== undefined && route != null && route.length > 0;

    }

    const throwInvalidRouteException = () => {
        throw 'Invalid route exception';
    }

    if (checkRouteOrQueryStringValidity(route)) {
        const response = await fetch(baseUrl + route, requestOptions);
        return await response.json();
    } else throwInvalidRouteException();
}
