import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDiscord} from '@fortawesome/free-brands-svg-icons'
import Typography from "@mui/material/Typography";
import {UnityLink} from "./Structural Components/UnityCommandsContext";
import Button from "@mui/material/Button";

export function DiscordButton() {
    return (
        <Button
            sx={{width:{xs:"100%", md:"fit-content"},justifyContent:{xs:"flex-start"}}}
            onClick={() => UnityLink("https://discord.com/invite/ZJEYKVGKkK")}
        >
                <FontAwesomeIcon icon={faDiscord} style={{color: "#ffffff",}}/>
                <Typography marginX={1}  noWrap={true}>Join the DISCORD</Typography>
        </Button>
    )
}