import {Box, Button, Stack} from "@mui/material";
import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";

export default function CreationCollapsable(props) {

    const [open, setOpen] = React.useState(false);

    function FrontEndDisplayButton() {
        return <Box
            display={"flex"}
            justifyContent={"flex-end"}
        >
            <Button
                variant="contained"
                type="button"
                disabled={props.disabled}
                color={"secondary"}
                sx={{justifyContent: "flex-end"}}
                onClick={() => setOpen(true)}
            >
                {props.title}
            </Button>
        </Box>
    }

    const {children, ValidationSchema, resetValues, callback} = props;

    const {register, reset, handleSubmit, control, formState, setValue, setFocus, formState: {errors, isSubmitSuccessful}} = useForm({
        resolver: yupResolver(ValidationSchema)
    });

    const onSubmit = (data) => submitPost(data);
    const onError = (errors, e) => console.log(errors, e);

    function submitPost(data) {
        callback(data);
    }

    useEffect(() => {
        if (isSubmitSuccessful) {
            setOpen(false);
        }
    }, [isSubmitSuccessful]);

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset(resetValues);
        }
    }, [formState, reset]);

    function handleClose() {
        console.log("Closing")
        setOpen(false);
    }

    function setClassName() {
        if (open) {
            return "list-card-container active-card-container"
        } else {
            return "list-card-container"
        }
    }

    let handleCloseCallback = ()=>handleClose();

    function MenuFieldWrappers({children}) {
        return <Box className={setClassName()} flexDirection={"column"}>
            <Box
                component={"form"}
                onSubmit={handleSubmit(onSubmit, onError)}
                sx={{display: 'flex', flexDirection: 'column', alignItems: 'left'}}
            >
                {React.cloneElement(children, {register, errors, control, setValue, setFocus, handleCloseCallback})}
            </Box>
        </Box>
    }

    return (
        <Box width={"100%"}>
            {open ? <MenuFieldWrappers children={children}/> : <FrontEndDisplayButton/>}
        </Box>
    )
}