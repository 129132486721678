import {Box, MenuItem, Select} from "@mui/material";
import React from "react";


export const DropdownSelection= ({name,register, options, label, defaultValue}) => {

    const GenerateSelectOptions = () => {
        return options.map((option, index) => {
            return (
                <MenuItem key={index} value={option}>
                    {option}
                </MenuItem>
            );
        });
    };

    return <Select
        labelId={`${name}-label`}
        label={label}
        {...register(name)}
        defaultValue={defaultValue}

        sx={{ marginRight: 1,
            height: 40,
            maxWidth: 200,
            width: {sm:`100%`, md: `50%`},
            '& .MuiSelect-select': {minWidth: 120}}}

        MenuProps={{
            sx: {
                width: 200,
            },
        }}
    >
        <GenerateSelectOptions/>
    </Select>
}

export const DropdownSelectionWithIcons = ({name,register, options, label, defaultValue,disabled}) => {

        const GenerateSelectOptions = () => {
            return options.map((option, index) => {
                return (
                    <MenuItem key={index} value={index}>
                        <Box display={`flex`} alignItems={`center`}>
                            {option.icon}
                            <Box ml={1}>
                                {option.value}
                            </Box>
                        </Box>
                    </MenuItem>
                );
            });
        };

        return <Select
            labelId={`${name}-label`}
            label={label}
            {...register(name)}
            defaultValue={defaultValue}
            disabled={disabled}

            sx={{ marginRight: 1,
                height: 40,
                maxWidth: "180px",
                width: {sm:`100%`, md: `100%`},
                backgroundColor: `#000000`,
                borderRadius: 3,
                '& .MuiSelect-select': {
                minWidth: 120,
                    display: `flex`,
                    justifyContent: `center`,
                    padding: 0,

            }}}

            MenuProps={{
                sx: {
                    width: 140,
                },
            }}
        >
            {GenerateSelectOptions()}
        </Select>
}
