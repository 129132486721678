import {Box, Link, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

export default function GettingStarted() {
    return (
        <Stack sx={{display: "flex", flexGrow: 1, flexDirection: "column", alignItems: "center"}} spacing={2}>
            <Typography variant={"h5"} fontSize={"2rem"}>GETTING STARTED with ANIMOTIVE</Typography>
            <Box>
                <Box className={"video-responsive"}>
                    <iframe
                        width="853"
                        height="480"
                        src={`https://www.youtube.com/embed/2y1Vu48-W6M?rel=0`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        title="Embedded youtube"
                    />
                </Box>
                <Stack sx={{width: "100%"}} direction={"row"} justifyContent={"center"} spacing={2} padding={3} borderRadius="0px 0px 14px 12px" backgroundColor={"#333333"}>
                    <img height={13} alt={'Meta Logo'} src={'./img/DownloadPage/MetaLogo.png'}/>
                    <img height={13} alt={'Valve Logo'} src={'./img/DownloadPage/ValveLogo.png'}/>
                    <img height={13} alt={'Vive Logo'} src={'./img/DownloadPage/ViveLogo.png'}/>
                    <img height={13} alt={'Pico Logo'} src={'./img/DownloadPage/PicoLogo.png'}/>
                </Stack>
            </Box>
            <Box className={"list-card-container active-card-container"} sx={{width: "100%"}}>
                <Typography sx={{width: "50%"}}>If you haven’t installed the ANIMOTIVE app or are experiencing issues,
                    please refer to the QUICK VR PC SET UP GUIDE (2 mns) <Link
                        href={"https://app.animotive.com/#/download"}>HERE</Link></Typography>
            </Box>
        </Stack>
    )
}