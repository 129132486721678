import {UnityLink} from "./Structural Components/UnityCommandsContext";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

export function HelpButton() {
    return (
        <Button direction={"row"} spacing={1}
                sx={{width:{xs:"100%", md:"fit-content"},justifyContent:{xs:"flex-start"}}}
               onClick={() => UnityLink('https://animotive.gitbook.io/animotive-documentation/')}
        >
            <HelpOutlineIcon color={"primary"}/>
            <Typography textTransform={"uppercase"} marginX={1} >Documentation</Typography>
        </Button>
    )
}