import Button from "@mui/material/Button";
import "./list-display-cards.scss";
import {Box, Stack, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";

function ProjectSelectionHeader(props) {

    function ViewProjectsButton(inheritedProps) {
        let props = inheritedProps.props

        return <Button
            component={RouterLink} to={props.ProjectID}
            state={{ProjectTitle: props.projectTitle, id: props.ProjectID}}
            color={"info"}
            sx={{paddingY: `8px`}}
        >Open</Button>;
    }

    function name(props) {
        if (props.className === "scene-button") {
            return "Scene: " + props.ProjectTitle
        } else {
            return props.ProjectTitle
        }
    }

    return <Box className={props.className} width={"100%"}>
        <Stack justifyContent={"space-between"} direction={"row"} width={"100%"} alignItems={"center"}>
            <Box sx={{flexGrow: 1, textOverflow:'ellipsis',maxWidth:"200px",overflow:"hidden"}}>
                <Typography sx={{fontWeight: `700px`,textOverflow:"ellipsis"}} noWrap={true} className={`project-selection-header`}>
                    {name(props)}
                </Typography>
            </Box>
            <ViewProjectsButton props={props}/>
        </Stack>
    </Box>

}

export default ProjectSelectionHeader;