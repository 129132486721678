import React from "react";
import {Box, Drawer, Table, TableBody, TableCell, TableRow} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function SystemRequirementsDrawer() {

    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setDrawerOpen(open);
    };

    return (
        <div style={{color: '#868B9A'}}>
            <Button variant={"text"} sx={{textDecoration: "underline"}} onClick={() => setDrawerOpen(true)}>Hardware
                Requirements</Button>
            <Drawer
                anchor={"bottom"}
                open={drawerOpen}
                onClose={toggleDrawer(false)}
            >
                <Box sx={{width: '100%', backgroundColor: "#868B9A", display:'flex', justifyContent:'center' }}>
                    <Box sx={{display: `flex`, alignSelf: `center`, flexDirection: `column`, padding: `20px`, width:"80%"}}>
                        <Typography sx={{width: `60%`}} variant={"h6"}>System Requirements for Animotive</Typography>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell><b>Component</b></TableCell>
                                    <TableCell><b>Recommended Specs</b></TableCell>
                                    <TableCell><b>Minimum Specs</b></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><b>Processor</b></TableCell>
                                    <TableCell>NVIDIA GTX 970/AMD Radeon R9 290 or greater</TableCell>
                                    <TableCell>Intel i3-6100/AMD Ryzen 3 1200, FX4350 or greater</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><b>Graphics Card</b></TableCell>
                                    <TableCell>NVIDIA GTX 1060/AMD Radeon RX 480 or greater</TableCell>
                                    <TableCell>NVIDIA GTX 1050 Ti/AMD Radeon RX 470 or greater</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><b>Memory</b></TableCell>
                                    <TableCell>8 GB+ RAM</TableCell>
                                    <TableCell>8 GB+ RAM</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><b>Operating system</b></TableCell>
                                    <TableCell>Windows 10+</TableCell>
                                    <TableCell>Windows 10+</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </Box>
            </Drawer>
        </div>
    )
}