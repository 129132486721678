import {Box, Divider} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useEffect} from "react";
import {Link as RouterLink} from "react-router-dom";
import {LaunchAnimotiveLogin} from "../functions/AnimotiveLaunchFunctions";

export default function LaunchPage() {

    useEffect(() => {
        LaunchAnimotiveLogin();
    }, []);

    return (
        <Box sx={{justifyContent: `center`, display: `flex`, alignItems: `self`}}>
            <Box
                width={"60%"}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                alignSelf={"center"}
            >
                <Typography variant="h3" pb={2}>
                    Animotive will be launching now
                </Typography>
                <Typography variant="h3" pb={2}>
                    If it doesn't launch, please ensure Animotive is installed and click the button below
                </Typography>
                <Button variant="contained" color={"secondary"} onClick={LaunchAnimotiveLogin}>
                    Launch
                </Button>
                <Divider sx={{width: "100%", my: 2}}/>
                <Typography variant={"h3"} pb={1}>
                    If you do not have Animotive installed, click Download and Install Animotive.
                </Typography>
                <Button variant="contained" color={"secondary"} component={RouterLink} to={"/download"}>
                    Download and Install Animotive
                </Button>
            </Box>
        </Box>
    )
}