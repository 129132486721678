import {store} from "../app/store";

export async function LaunchAnimotiveLogin(){
    const state = store.getState();
    console.log(state.loginToken)
    const token = "-token=" + state.loginToken.userTokens.accessToken;
    const username = "-username=" + state.loginToken.emailAddress;
    window.location = "Animotive:/" + " " + token + " " + username;
}

export async function LaunchAnimotiveWithSession(sessionID){
    const state = store.getState();
    const token = "-loginToken=" + state.loginToken.userTokens.accessToken;
    console.log("Animotive://" + token + sessionID)
    window.open("Animotive://" + token + " " + sessionID);
}