import {Box, Button} from "@mui/material";
import Typography from "@mui/material/Typography";
import {ArrowBackIosNew} from "@mui/icons-material";
import {useDispatch} from "react-redux";
import {Link as RouterLink} from "react-router-dom";
import {setCurrentView, setProjectNavigationIndex} from "../../features/states/ProjectFunctions";

export default function ReturnToProjectList() {

    const dispatch = useDispatch();

    function handleClick() {
        dispatch(setCurrentView(""))
        dispatch(setProjectNavigationIndex(0))
        if(window.vuplex) {
            const params = {
                type: 'projectInfo',
                value: null,
            }
            window.vuplex.postMessage(params)
        }
    }

    return (
        <Box className={`return-button`} sx={{height:56, paddingTop:"12px"}}>
            <Button onClick={handleClick} component={RouterLink} to={"/projects"}
            >
                <ArrowBackIosNew/>
                <Typography textTransform={"uppercase"} whiteSpace={"nowrap"}>Project List</Typography>
            </Button>
        </Box>
    )
}