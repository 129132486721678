import React, {useEffect} from "react";
import {
    Box, CircularProgress, DialogActions, Divider, FormControlLabel, Stack, TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {TemplateSelectionImageButtonGroup} from "../ImageButtonGroup";
import * as Yup from 'yup';
import Posts from "../../api-calls/Posts";
import {selectUserStorageLimit} from "../../features/states/ProjectFunctions";
import {useSelector} from "react-redux";
import CreationCollapsable from "../Structural Components/CreationCollapsable";
import GetUserProjects from "../../functions/ServerCallFunctions";
import GetsDirect from "../../api-calls/GetsDirect";
import ErrorModal from "../Structural Components/ErrorModal";
import {SendMessageToUnity} from "../Structural Components/UnityCommandsContext";

const ValidationSchema = Yup.object().shape({
    projectTitle: Yup.string().required("Project name is required").max(50, "Project name is too long").test('noSpecialChars', 'Project name cannot contain special characters', async (value) => {
        return Yup.string().matches(/^[a-zA-Z0-9 ]*$/, 'Project name cannot contain special characters').isValidSync(value);
    }),
});

const resetValues = {
    isPro: true, projectTitle: ''
}

const CollapsableContent = ({errors, register, control, setValue, setFocus, handleCloseCallback}) => {

    useEffect(() => {
        setFocus("projectTitle")
    }, [setFocus])

    return <>
        <Typography variant={`h6`}>Select a Template</Typography>
        <TemplateSelectionImageButtonGroup register={register} control={control} setValue={setValue}/>
        <TextField
            color={"info"}
            id="projectTitle"
            hiddenLabel={true}
            label={"Project Name"}
            name="projectTitle"
            {...register("projectTitle", {required: "Project Name is required"})}
            size={"small"}
            sx={{marginY: 1}}
            error={!!errors.projectTitle}
        />
        <Typography variant={"caption"} color={"error"}>{errors.projectTitle?.message}</Typography>
        {/*
                <Divider sx={{marginY: 1}}/>
                <Typography>Region Select:</Typography>
                {displayRegionSelection()}
         */}

        <input type={`hidden`}
               {...register("regionSystemName")}
               value={`eu-west-2`}
        />


        <Divider sx={{marginY: 1}}/>
        <Stack direction={"row"} justifyContent={"flex-end"}>
            <DialogActions>
                <Button variant={"contained"} color={"secondary"} onClick={handleCloseCallback}>Cancel</Button>
            </DialogActions>
            <DialogActions>
                <Button variant={"contained"} color={"secondary"} type={`submit`}>Create</Button>
            </DialogActions>
        </Stack>
    </>
}

export default function CreateProjectFormComponent() {

    const [currentlyCreating, setCurrentlyCreating] = React.useState(false);

    const storageLimit = useSelector(selectUserStorageLimit)

    const [errorModalSettings, setErrorModalSettings] = React.useState({
        open: false, title: "", message: ""
    });

    function handleErrorClose() {
        setErrorModalSettings({open: false, title: "", content: ""});
    }

    async function CreateProject(data) {

        setCurrentlyCreating(true)

        async function getStorageSpace() {
            let spaceValidation = await GetsDirect('Projects/get-storage-size');
            if (spaceValidation.code === 200) {
                if (spaceValidation.data !== undefined) {
                    return spaceValidation.data.totalGbs;
                } else {
                    return 0;
                }
            }
        }

        data["message"] = "Project Creation";

        let storageUsed = await getStorageSpace();

        if (storageUsed < storageLimit) {
            if (data.templateName === undefined) {
                data.templateName = "Blank";
            }

            let response = await Posts(data, `Projects/create-project`);

            SendMessageToUnity("server-response", response);

            if (response.code === 200) {
                GetUserProjects();
            }
        } else {
            setErrorModalSettings({
                open: true,
                title: "Project Creation Failed",
                content: `You have reached your storage limit of ${storageLimit}GB.`
            })
        }
        setCurrentlyCreating(false)
    }

    if (currentlyCreating) {
        return <Box>
            <Typography>Creating Project, this may take a few seconds</Typography>
            <CircularProgress/>
        </Box>
    }

    return <Box sx={{marginTop:`8px`}}>
        <CreationCollapsable
            title={`Create a new project`}
            ValidationSchema={ValidationSchema}
            resetValues={resetValues}
            callback={CreateProject}
        >
            <CollapsableContent/>
        </CreationCollapsable>
        <ErrorModal settings={errorModalSettings} closeCallback={handleErrorClose}/>
    </Box>


}