import {Box, FormControlLabel, Stack, TextField} from "@mui/material";
import './TeamMemberRow.scss'
import React from 'react'
import {DropdownSelectionWithIcons} from "./TeamUIComponents/DropdownSelection";
import {CheckboxSelection} from "./TeamUIComponents/CheckboxSelection";
import {TeamMemberRoles} from "../InformationReferences/TeamMemberRoles";
import "./list-display-cards.scss"
import Typography from "@mui/material/Typography";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yup from 'yup';
import CollapsibleCardHeading from "./Structural Components/EditCardPanel";
import Posts from "../api-calls/Posts";
import {GetTeamInfoFromServer} from "../functions/ServerCallFunctions";
import {Circle} from "@mui/icons-material";
import InlineConfirmationWindow from "./Structural Components/InlineConfirmationWindow";
import DeleteAPI from "../api-calls/Delete";
import {useSelector} from "react-redux";
import {selectUserId} from "../features/states/ProjectFunctions";
import Button from "./Structural Components/ConditionalButtons";

function TeamMemberCollapsable({item, index, projectId, isAdmin}) {

    const ValidationSchema = yup.object().shape({
        teamMembers: yup.array().of(
            yup.object().shape({
                id: yup.string(),
                userRole: yup.string().required(),
                isAdmin: yup.boolean(),
                isPending: yup.boolean(),
            })
        )
    });

    const {register, reset, handleSubmit} = useForm({
        resolver: yupResolver(ValidationSchema)
    });

    const handleDeleteMember = () => {

        let route;
        let data;

        if (item.pendingAcceptInvite) {
            route = "UserInvite/delete-invite"
            data = {
                inviteId: item.inviteId
            }
        } else {
            route = "Projects/delete-users"
            data = {
                projectId: projectId,
                projectUserId: item.userId
            }
        }

        DeleteAPI(data, route).then(response => {
            if (response.code === 200) {
                GetTeamInfoFromServer(projectId)
            }
        })
    }

    function CheckPending() {
        if (item.pendingAcceptInvite) {
            return (
                <Box sx={{
                    minWidth: 160,
                    maxWidth: 200,
                    display: `inline-flex`
                }} className="pending">
                    <Circle color={"error"}/> <Typography color={"info"} sx={{marginLeft: 1}}>Invitation
                    Pending</Typography>
                </Box>
            )
        }
    }

    function ShowAdminCheckbox() {
        if (item.isProjectOwner || item.pendingAcceptInvite) {
            return null
        }
        return <FormControlLabel
            sx={{
                maxWidth: "130px",
                paddingTop: 3,
            }}
            control={
                <CheckboxSelection
                    disabled={!item.isAdmin && !item.isProjectOwner}
                    name={`isAdmin`}
                    defaultValue={item.isAdmin}
                    label="Admin"/>
            }
            {...register(`isAdmin`)}
            label="Administrator"
        />
    }

    function DisplayCard() {
        return <Box display={"flex"} flexWrap={"wrap"} width={"100%"}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
                <Typography sx={{flexGrow: 1}}>{displayUsernameOrEmail()}</Typography>
                <Stack direction={"row"}>
                    <Box sx={{mx: 1, fontSize: `2rem`}}>
                        {TeamMemberRoles[item.userRole].icon}
                    </Box>
                    <Typography sx={{flexGrow: 1}}>{TeamMemberRoles[item.userRole].value}</Typography>
                </Stack>
            </Stack>
            <Stack direction={"row"}>
                <CheckPending/>
            </Stack>
        </Box>
    }
    
    function SubmitForm(){
        if(!item.pendingAcceptInvite) {
            return <Button
                visible={isAdmin}
                type={"submit"}
                color={"info"}
            >
                Save
            </Button>
        }
    }

    function RemoveUserButton(props) {
        const userID = useSelector(selectUserId)
        if (item.userId !== userID && !item.isProjectOwner)
            return <Button
                visible={item.isAdmin}
                className={`text-btn`}
                variant={'text'}
                color={'error'}
                onClick={props.callback}
                disabled={item.isOwner}
            >
                Remove User
            </Button>
    }

    function EditBox({callback}) {
        const onSubmit = (data) => submitPost(data);
        const onError = (errors, e) => console.log(errors, e);

        function LeaveEditMenu() {
            callback(false)
            reset()
        }

        async function submitPost(data) {

            const newData = {
                userId: item.userId,
                role: parseInt(data.userRole),
                isAdmin: item.isProjectOwner || data.isAdmin,
                projectId: projectId,
            }
            const response = await Posts(newData, "Projects/update-user-project-role")
            response.code === 200 ? Refresh() : console.log(response)

            function Refresh() {
                GetTeamInfoFromServer(projectId)
                callback(false)
            }
        }

        displayUsernameOrEmail()

        return <Box component={"form"}
                    onSubmit={handleSubmit(onSubmit, onError)}
                    className={"edit-box"}
        >
            <Stack direction={"column"} spacing={1}>
                <TextField
                    label={"Username or unique ID"}
                    defaultValue={displayUsernameOrEmail()}
                    variant={"outlined"}
                    disabled
                    fullWidth
                />
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <FormControlLabel
                        control={<DropdownSelectionWithIcons
                            key={item.id}
                            name={`userRole`}
                            register={register}
                            defaultValue={item.userRole}
                            options={TeamMemberRoles}
                            disabled={!isAdmin}
                        />}
                        label={"Team Member Role"}
                        labelPlacement={"top"}
                        sx={{alignItems: `flex-start`, margin: 0}}
                    />
                    <ShowAdminCheckbox/>
                </Stack>

                <Box
                    sx={{
                        display: `flex`,
                        minWidth: 203,
                        flexGrow: 1,
                        width: "100%",
                        justifyContent: `space-evenly`,
                    }}
                >
                    <InlineConfirmationWindow approvalAction={handleDeleteMember} DisplayComponent={<RemoveUserButton/>}>
                        <Typography component="div">
                            Are you sure you want to remove this user? They will lose access to the project files
                        </Typography>
                    </InlineConfirmationWindow>
                    <Button color={`info`} onClick={LeaveEditMenu}>Cancel</Button>
                    <SubmitForm/>
                </Box>
                <input type={"hidden"} name={`teamMembers[${index}]`}
                       defaultValue={item.userId} {...register(`id`)}/>
            </Stack>
        </Box>
    }

    function displayUsernameOrEmail() {
        if (item.displayName) {
            return item.displayName
        } else {
            return item.userEmail
        }
    }

    return (
        <CollapsibleCardHeading key={item.id} canEdit={isAdmin} title={displayUsernameOrEmail()} displayComponent={<DisplayCard/>}
                                editComponent={<EditBox/>}/>
    );
}

export default TeamMemberCollapsable;