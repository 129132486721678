import {Box, Button, Divider, Stack, TextField} from "@mui/material";
import {useFieldArray, useForm} from "react-hook-form";
import Typography from "@mui/material/Typography";
import React, {useEffect} from "react";
import Posts from "../api-calls/Posts";
import {getTokenValueFromAWS} from "../functions/AmplifyFunctions";
import {HubConnectionBuilder} from "@microsoft/signalr";

export default function ScriptGenerator() {

    const [scriptPrompt, setScriptPrompt] = React.useState("")

    const {register, handleSubmit, control, formState: {}} = useForm();

    let message = ""

    const {fields: characterFields, append: appendCharacter, remove: removeCharacter} = useFieldArray({
        control,
        name: "characters"
    });

    const {fields: propFields, append: appendProp, remove: removeProp} = useFieldArray({
        control,
        name: "props"
    })

        function CreateScript(data) {

        const intro = "Write me a script for a scene in a film"
        const title = data["project-title"] !== "" ? " with the title " + data["project-title"] + ". " : ""
        const sceneTitle = data["scene-title"] !== "" ? "The scene is called " + data["scene-title"] + ". " : ""
        const sceneLocation = data["location"] !== "" ? "It is set " + data["location"] + ". " : ""
        const sceneGenre = data["genre"] !== "" ? "The genre is " + data["genre"] + ". " : ""
        const sceneDescription = data["description"] !== "" ? data["description"] + "." : ""
        const characters = "The Scene should only include the following characters: " + data["characters"].map((item) => CreateCharacterDescription(item)).join(", ")
        const props = data["props"] !== "" ? "The characters should only have direct interaction with the following props, though their inclusion is optional: " + data["props"].map((item) => item.name) + ". " : ""

        function CreateCharacterDescription(character) {
            return character.name + " who is " + character.description
        }

        const prompt = intro + title + sceneTitle + sceneLocation + sceneGenre + sceneDescription + characters + props
        setScriptPrompt(prompt)
    }

    const CharacterAdditionFields = () => {
        return <Stack direction={"column"} spacing={2}>
            <Typography variant={"h6"}>Characters</Typography>
            <Stack direction={"column"} spacing={1}>
                {characterFields.map((item, index) => (
                    <Stack direction={"row"} key={item.id} marginY={1} spacing={1} className={"list-card-container"}
                           maxWidth={"100%"}>
                        <TextField label={"Character Name"}
                                   variant={"outlined"} {...register(`characters.${index}.name`)} fullWidth/>
                        <TextField label={"Character Description"}
                                   variant={"outlined"} {...register(`characters.${index}.description`)} fullWidth/>
                        <Button onClick={() => removeCharacter(index)}>Delete</Button>
                    </Stack>
                ))}
            </Stack>
            <Button variant={"contained"} color={"secondary"}
                    onClick={() => appendCharacter({name: "test", description: "test"})}>Add Character</Button>
        </Stack>
    }

    const PropsAdditionFields = () => {
        return <Stack direction={"column"} spacing={2}>
            <Typography variant={"h6"}>Props</Typography>
            <Stack direction={"column"} spacing={1}>
                {propFields.map((item, index) => (
                    <Stack direction={"row"} key={item.id} marginY={1} spacing={1} className={"list-card-container"}
                           maxWidth={"100%"}>
                        <TextField label={"Prop Name"} variant={"outlined"} {...register(`props.${index}.name`)}
                                   fullWidth/>
                        <Button onClick={() => removeProp(index)}>Delete</Button>
                    </Stack>
                ))}
            </Stack>
            <Button variant={"contained"} color={"secondary"} onClick={() => appendProp({name: "test"})}>Add
                Prop</Button>
        </Stack>
    }

    function ScriptGenerationForm() {
        return <Box spacing={2} component={"form"} onSubmit={handleSubmit(data => CreateScript(data))} flex>
            <TextField sx={{marginY: 1}} label={"Project Title"} variant={"outlined"} {...register('project-title')}
                       fullWidth/>
            <TextField sx={{marginY: 1}} label={"Scene Title"} variant={"outlined"} {...register('scene-title')}
                       fullWidth/>
            <TextField multiline={true} sx={{marginY: 1}} label={"Description"}
                       variant={"outlined"} {...register('description')} fullWidth/>
            <TextField sx={{marginY: 1}} label={"Location"} variant={"outlined"} {...register('location')} fullWidth/>
            <TextField sx={{marginY: 1}} label={"Genre"} variant={"outlined"} {...register('genre')} fullWidth/>
            <Divider sx={{marginY: 2}}/>
            <CharacterAdditionFields/>
            <Divider sx={{marginY: 2}}/>
            <PropsAdditionFields/>
            <Stack direction={"column"} spacing={2}>
                <Button variant={"contained"} color={"info"} type={"submit"}>Submit</Button>
            </Stack>
        </Box>
    }

    function Output({prompt}) {

        const [output, setOutput] = React.useState("")
        const [connection, setConnection] = React.useState()
        const [connectionID, setConnectionID] = React.useState()

        useEffect(() => {
            console.log(prompt)
            if(prompt !== "") {
                console.log("test")
                callOpenAI()
            }
        }, [prompt])

        useEffect(() => {
            if(connection !== undefined) {
                startMessageListener(connection)
            }
        }, [connection])

        useEffect(() => {
            if(connectionID !== undefined) {
                sendPromptToAPI()
            }
        }, [connectionID])

        async function callOpenAI() {
            let Connection= null
            getTokenValueFromAWS().then((token) => {
                    const auth = token
                    Connection = new HubConnectionBuilder()
                        .withUrl("https://api.animotive.com/ai-response-streaming-hub/", {
                            accessTokenFactory: () => auth
                        })
                        .build();
                    setConnection(Connection)
                }
            )
        }

        async function sendPromptToAPI(){
            console.log(connection)
            const route = "AI/generate-script"
            const params = {
                query: prompt,
                connectionId: connection.connectionId
            }

            console.log(params)

            const response = await Posts(params, route)
            if (response.code === 200) {
                console.log(response)
            }
        }

        async function startMessageListener(connection) {
            message= ""

            connection.on("ReceiveMessage", (message) => {
            })

            connection.on("ReceiveNotification", (notification) => {
                setMessage(notification)
            })

            connection.start().then(() => {
                setConnectionID(connection.connectionId)
            })
        }

        function setMessage(notification) {
            message = message + notification
            setOutput(message)
        }

        return <Box>
            <Typography variant={"h6"}>Output</Typography>
            <Typography sx={{whiteSpace: "pre-line"}}>{output}</Typography>
        </Box>
    }

    return <Box sx={{maxWidth: "700px", justifyContent: "flex-start", display: "flex", flexDirection: "column"}}
                className={"centre-box"}>
        <Box sx={{width: "100%", justifyContent: "center", display: "flex"}}>
            <Typography variant={"h4"}>Script Generator</Typography>
        </Box>
        <ScriptGenerationForm/>
        <Output prompt={scriptPrompt}/>
    </Box>

}