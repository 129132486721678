import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";

function ConfirmationDialogWindow(props)
{
    const {onClose, open} = props;

    const handleListItemClick = (value) => {
        onClose(value, props.callback);
    };

    return (
        <Dialog open={open}>
            <DialogTitle id="dialogComponent">Are you sure?</DialogTitle>
            <DialogContent>
                <Button onClick={() => handleListItemClick(true)} value={true}>Yes</Button>
                <Button onClick={() => handleListItemClick(false)} value={false}>No</Button>
            </DialogContent>
        </Dialog>
    )
}

export default ConfirmationDialogWindow;