import {getTokenValueFromAWS} from "../functions/AmplifyFunctions";

export default async function GetsDirect(route) {

    let requestOptions;
    await getTokenValueFromAWS().then((token) => {
        requestOptions = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + token,
                'accept': 'application/json',
            },
        };
    });


    let baseUrl;

    if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        baseUrl = process.env.REACT_APP_DEV_ENDPOINT_BASE_URL;
    } else {
        baseUrl = process.env.REACT_APP_ENDPOINT_BASE_URL;
    }

    const checkRouteOrQueryStringValidity = (route) => {
        return route !== undefined && route != null && route.length > 0;

    }
    const throwInvalidRouteException = () => {
        throw 'Invalid route exception';
    }

    if (checkRouteOrQueryStringValidity(route)) {
            const response = await fetch(baseUrl + route, requestOptions);
            switch (response.status) {
                case 200:
                    return await response.json();
                case 401:
                    window.location.href = "/";
                    break
                default:
                    throw response.statusText;
            }


    } else throwInvalidRouteException();
}

export async function GetsCustom(route, requestOptions) {

    let baseUrl;

    if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        baseUrl = process.env.REACT_APP_DEV_ENDPOINT_BASE_URL;
    } else {
        baseUrl = process.env.REACT_APP_ENDPOINT_BASE_URL;
    }

    const checkRouteOrQueryStringValidity = (route) => {
        return route !== undefined && route != null && route.length > 0;

    }
    const throwInvalidRouteException = () => {
        throw 'Invalid route exception';
    }

    if (checkRouteOrQueryStringValidity(route)) {
        const response = await fetch(baseUrl + route, requestOptions);
        switch (response.status) {
            case 200:
                return await response.json();
            case 401:
                window.location.href = "/";
                break
            default:
                throw response.statusText;
        }


    } else throwInvalidRouteException();
}

