import {Box, Button, Stack} from "@mui/material";
import React, { useState} from "react";

export default function InlineConfirmationWindow({approvalAction, children, DisplayComponent}) {
    const [showConfirmation, setShowConfirmation] = useState(false);

    const ConfirmationWindow = () => {
        return <Box
            className={"confirmation-window list-card-container active-card-container"}
            sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between"}}
        >
            <Stack>
                {children}
            </Stack>
            <Box className={"confirmation-window-buttons"} display={"flex"} flexDirection={"row"} alignItems={"center"}>
                <Button onClick={approvalAction} color={"secondary"} sx={{height: "50px", margin: "5px"}}
                        variant={"contained"}>Yes</Button>
                <Button onClick={cancel} color={"secondary"} sx={{height: "50px", margin: "5px"}}
                        variant={"contained"}>No</Button>
            </Box>
        </Box>
    }

    function callback() {
        setShowConfirmation(true);
    }

    function cancel() {
        setShowConfirmation(false);
    }

    if (showConfirmation) {
        return <ConfirmationWindow/>
    } else {
        return  React.cloneElement(DisplayComponent, {callback: callback});
    }
}