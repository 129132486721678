import {getTokenValueFromAWS} from "../functions/AmplifyFunctions";

export default async function Posts(params, route) {

    let requestOptions;
    await getTokenValueFromAWS().then((token) => {
        requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + token,
                'content-type': 'application/json',
            },
            body: JSON.stringify(params),
        };
    })

    const checkRouteOrQueryStringValidity = (route) => {
        return route !== undefined && route != null && route.length > 0;

    }
    const throwInvalidRouteException = () => {
        throw 'Invalid route exception';
    }

    let baseUrl;

    if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        baseUrl = process.env.REACT_APP_DEV_ENDPOINT_BASE_URL;
    } else {
        baseUrl = process.env.REACT_APP_ENDPOINT_BASE_URL;
    }

    if (checkRouteOrQueryStringValidity(route)) {
        if (params !== undefined && params != null) {
            const fetchResponse = await fetch(baseUrl + route, requestOptions)
            return await fetchResponse.json();
        }
    } else throwInvalidRouteException();
}
