import {Box, CircularProgress} from "@mui/material";
import HubspotForm from "react-hubspot-form";
import React from "react";
import "./styles/Survey.scss"
import Typography from "@mui/material/Typography";

export function EnterpriseContactForm(){

        return (<Box sx={{marginTop: "10px"}} component={"form"}>
            <Box className={"centre-box"}>
                <Box className={"inner-box"} sx={{maxWidth:"400px"}}>
                    <Typography variant={"h4"}>Enterprise Contact</Typography>
                    <HubspotForm
                        portalId='8788699'
                        formId='44e4150a-0182-4be6-83d4-0b4e361f2694'
                        cssClass={"survey-form"}
                        loading={<CircularProgress/>}
                    />
                </Box>
            </Box>
        </Box>)
}