import React from "react";
import {Box, Divider, IconButton, Link, Stack, SvgIcon, Toolbar} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import MetaAccountPanels from "./MetaAccountPanels";
import Typography from "@mui/material/Typography";
import {ReactComponent as AnimotiveHeaderIcon} from "../img/AnimotiveHeaderLogo.svg";
import Button from "@mui/material/Button";
import {useSelector} from "react-redux";
import {
    selectCurrentView,
    selectLoginState,
    selectUnity
} from "../features/states/ProjectFunctions";
import {PrimaryNavTabs, ProjectNavTabs} from "./Nav Bar Components/NavigationTabs";
import ReturnToProjectList from "./Nav Bar Components/ReturnToProjectListComponent";
import MenuIcon from '@mui/icons-material/Menu';

import './NavigationCSS.scss';
import {HelpButton} from "./HelpButton";
import {DiscordButton} from "./DiscordButton";
import MyAccountButton from "./MyAccountButton";
import {SignOutOfClient} from "../functions/AmplifyFunctions";

function NavigationSelector() {
    const isProjectView = (useSelector(selectCurrentView) === "projects");

    if (!isProjectView)
        return <PrimaryNavTabs/>
    else
        return <ProjectNavTabs/>
}

function ProjectOnlyComponents() {
    const isProjectView = (useSelector(selectCurrentView) === "projects");
    if (isProjectView)
        return (
            <Stack className={"mobile-nav-project-stack"} direction={{md: "row", sm: "column"}}
                   sx={{alignItems: "flex-start"}}>
                <ReturnToProjectList/>
            </Stack>
        )

    else
        return <></>
}

function SettingsOnlyMobileToolbar() {
    const isSettingsView = (useSelector(selectCurrentView) === "Account Settings");
    if (isSettingsView) {
        return <Toolbar
            className={`mobile-nav-project-only settings`}
            sx={{
                minHeight: 112,
                padding: 0,
                width: {sm: `calc(100%)`},
                '& .MuiToolbar-root': {minHeight: 56},
                display: {sm: 'flex', md: 'none'},
            }}>
        </Toolbar>
    }
}

function ProjectOnlyMobileToolbar() {
    const isProjectView = (useSelector(selectCurrentView) === "projects");
    if (isProjectView) {
        return <Toolbar
            className={`mobile-nav-project-only`}
            sx={{
                minHeight: 112,
                padding: 0,
                width: {sm: `calc(100%)`},
                '& .MuiToolbar-root': {minHeight: 56},
                display: {sm: 'flex', md: 'none'},
            }}>
            <ProjectOnlyComponents/>
        </Toolbar>
    }
}

export function ClosedBetaBar() {
    return <AppBar
        position={"fixed"}
        sx={{top: 'auto', bottom: 0}}>
        <Toolbar sx={{position: 'fixed', bottom: 0, width: "100%", display: `flex`, justifyContent: `center`}}>
            <Typography>Animotive is currently in closed Beta, please stick to the terms of the beta and do not publish
                content publicly Until the full launch (date TBC).</Typography>
        </Toolbar>
    </AppBar>
}

export function Header() {
    const userLoggedIn = useSelector(selectLoginState)
    const isUnity = useSelector(selectUnity);
    const [mobileNavOpen, setMobileNavOpen] = React.useState(false);
    const navigate = useNavigate();

    function MobileNavigationMenu() {

        function goToSettings(){
            navigate(`/settings`);
            setMobileNavOpen(false);
        }

        if(mobileNavOpen){
            return <Toolbar
                className={`mobile-nav`}
                sx={{
                    height: 250,
                    padding: 0,
                    width: {sm: `calc(100%)`},
                    '& .MuiToolbar-root': {minHeight: 56},
                    display: {sm: 'flex', md: 'none'},
                }}>
                <Stack width={"100%"} alignItems={"flex-start"} height={"100%"} marginX={2} spacing={0} justifyContent={"space-evenly"}>
                    <MyAccountButton callback={goToSettings}/>
                    <Divider sx={{width:"100%", borderColor:"black"}}/>
                    <HelpButton sx={{width:"100%"}}/>
                    <Divider sx={{width:"100%", borderColor:"black"}}/>
                    <DiscordButton sx={{width:"100%"}}/>
                    <Divider sx={{width:"100%", borderColor:"black"}}/>
                    <SignOutButton/>
                    <Divider sx={{width:"100%", borderColor:"black"}}/>
                </Stack>
            </Toolbar>
        }
    }

    function SignOutButton(){

        function signOutFlow(){
            SignOutOfClient();
            navigate(`/`);
        }
        if(userLoggedIn){
            return <Button
                onClick={()=>signOutFlow()}
                fullWidth
                sx={{textTransform:"uppercase",justifyContent:{xs:"flex-start"}}}>Sign Out</Button>
        }
    }

    function GetHomeIcon() {
        if (!isUnity) {
            return <Stack sx={{maxHeight: "56px"}} direction={"row"} className={"home-icon"}>
                <Link href="https://www.animotive.com" color="inherit" underline="none"
                      sx={{textDecoration: `none`, display: "flex "}}>
                    <SvgIcon component={AnimotiveHeaderIcon} inheritViewBox/>
                    <Typography
                        variant="h6"
                        noWrap
                        display={"flex"}
                        sx={{textDecoration: `none`}}
                    >
                        .com
                    </Typography>
                </Link>
            </Stack>;
        }
    }

    function DesktopNavBar() {
        return <Toolbar
            className={`nav`}
            variant={"dense"}
            sx={{
                minHeight: 56,
                width: {md: `calc(100%)`},
                '& .MuiToolbar-root': {minHeight: 56},
                display: {xs: 'none', md: 'flex'},

            }}>
            <Box className={"nav-bar-left"} sx={{maxHeight: "56px"}}>
                <GetHomeIcon/>
            </Box>
            <Box className={"nav-bar"}>
                <ProjectOnlyComponents/>
                <NavigationSelector/>
            </Box>
            <Box className={"nav-bar-right"}>
                <MetaAccountPanels/>
            </Box>
        </Toolbar>;
    }

    function MobileNavBar() {
        return <>
            <Toolbar
                className={`nav`}
                variant={"dense"}
                sx={{
                    minHeight: 56,
                    width: {sm: `calc(100%)`},
                    '& .MuiToolbar-root': {minHeight: 56},
                    display: {xs: 'flex', md: 'none'},
                }}>
                <Box className={"nav-bar-left"}>
                    <GetHomeIcon/>
                </Box>
                <Box className={"nav-bar-right"}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        onClick={() => setMobileNavOpen(!mobileNavOpen)}
                        sx={{mr: 2}}>
                        <MenuIcon/>
                    </IconButton>
                </Box>
            </Toolbar>
            <Divider
                sx={{
                    borderBottomWidth: 2,
                    display: {xs: 'flex', md: 'none'},
                }}
            />
            <MobileNavigationMenu/>
            <ProjectOnlyMobileToolbar/>
            <SettingsOnlyMobileToolbar/>
            <Toolbar
                className={`nav`}
                variant={"dense"}
                sx={{
                    minHeight: 56,
                    width: {sm: `calc(100%)`},
                    '& .MuiToolbar-root': {minHeight: 56},
                    display: {xs: 'flex', md: 'none'},

                }}>
                <Box className={"nav-bar"}>
                    <NavigationSelector/>
                </Box>
            </Toolbar>
        </>
    }

    function LoggedOutNavBar() {
        return <Toolbar
            className={`nav`}
            variant={"dense"}
            sx={{
                minHeight: 56,
                width: {sm: `calc(100%)`},
                '& .MuiToolbar-root': {minHeight: 56},
                display: {xs: 'flex', md: 'flex'},
            }}>
            <Box className={"nav-bar-left"}>
                <GetHomeIcon/>
            </Box>
            <Box className={"nav-bar-right"}>
                <Button
                    color={"inherit"}
                    component={RouterLink}
                    to={"/"}
                >
                    Login
                </Button>
            </Box>
        </Toolbar>
    }

    function CheckForLogin({children}) {

        if (userLoggedIn)
            return children;
        else
            return (<LoggedOutNavBar/>)
    }

    return (
        <>
            <AppBar
                position="sticky"
                sx={{
                    height: 'fit-content',
                    minHeight: 56,
                    width: {sm: `calc(100%)`},
                    '& .MuiAppBar-root': {minHeight: 56},
                }}
            >
                <CheckForLogin>
                    <MobileNavBar/>
                    <DesktopNavBar/>
                </CheckForLogin>
            </AppBar>
        </>
    );
}
