import {useEffect, useState} from "react";
import {selectInvites, setPrimaryNavigationIndex} from "../features/states/ProjectFunctions";
import {useDispatch, useSelector} from "react-redux";
import InvitePanel from "./InvitePanel";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import {GetInvitesFromServer} from "../functions/ServerCallFunctions";

export function InviteList() {
    const inviteList = useSelector(selectInvites);
    const [invitesExist, setInvitesExist] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        GetInvitesFromServer();
        dispatch(setPrimaryNavigationIndex(2));
    }, []);

    useEffect(() => {
        if (inviteList !== null && inviteList.length > 0) {
            setInvitesExist(true)
        } else {
            setInvitesExist(false)
        }
    }, [inviteList])

    function GetListOfPendingInvites() {
        if (invitesExist) {
            return (inviteList.map((invite) => (
                    <InvitePanel
                        key={invite.projectId}
                        invite={invite}
                    />
                ))
            )
        }
        else {
            return(
                <NoInvitationHeader/>
            )
        }
    }

    function NoInvitationHeader(){
            return(
                <Box className={"list-card-container card-container"} width={"100%"} maxWidth={"100%"} flexDirection={"column"}  alignItems={"center"}>
                    <Typography component="div">
                        You have no pending invites
                    </Typography>
                </Box>
            )
    }

    return (
        <Box className="invite-list" >
                <GetListOfPendingInvites/>
        </Box>
    );
}