import {
    Box,
    CircularProgress,
    Stack,
    TextField
} from "@mui/material";
import Button from "./Structural Components/ConditionalButtons";
import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import * as Yup from 'yup';
import {useSelector} from "react-redux";
import {selectCurrentProject, selectUnity} from "../features/states/ProjectFunctions";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import CollapsibleCardHeading from "./Structural Components/EditCardPanel";
import {getNewTokenValueFromAWS} from "../functions/AmplifyFunctions";
import SessionLivePanel from "./TeamUIComponents/LiveNotification";
import {countActiveUsersInProject, getCCULimit} from "../functions/ValidationFunctions";

function SceneSelectionCollapsable(props) {

    const project = useSelector(selectCurrentProject);
    const isOwner = project.isOwner;

    let dateCreated = new Date(props.field.createdAt).toLocaleDateString() + " " + new Date(props.field.createdAt).toLocaleTimeString();
    let dateModified = new Date(props.field.updatedAt).toLocaleDateString() + " " + new Date(props.field.updatedAt).toLocaleTimeString();

    const ValidationSchema = Yup.object().shape({
        sceneName: Yup.string()
            .required('Required'),
    });

    const openInUnity = useSelector(selectUnity);

    const {register, reset, handleSubmit, formState: {errors}} = useForm({
        defaultValues: {sceneName: props.field.sceneName},
        resolver: yupResolver(ValidationSchema),
    });

    function handleDeleteCall() {
        props.openMenu(props.field.sceneKey)
    }

    useEffect(() => {
        reset({sceneName: props.field.sceneName});
    }, [props.field, reset]);

    const onSubmit = (data) => {
        props.callback(props.field.sceneKey, data.sceneName);
    }

    const contextMessageBase = () => {
        return "This project has reached the maximum number of concurrent users. \n Please try again later or"
    }

    const contextMessageExtension = () => {
        if (isOwner) {
            return "upgrade your project to increase the number of concurrent users."
        } else {
            return "contact the project owner about upgrading the project."
        }
    }

    async function webReturnSceneInfo() {

        let userLimit = getCCULimit()

        let response = await countActiveUsersInProject(props.projectID, userLimit);
        if (response) {
            const token = await getNewTokenValueFromAWS();

            const sceneInfo = {
                type: "sceneInfo",
                projectGuid: props.projectID,
                sceneGuid: props.field.sceneKey,
                loginToken: token
            }

            if (window.vuplex) {
                window.vuplex.postMessage(sceneInfo);
            }
        } else {
            props.errorModal({
                open: true,
                title: "User Limit Reached",
                content: contextMessageBase() + "\n" + contextMessageExtension()
            })
        }

    }

    function SceneJoinButton() {
        if (!openInUnity) {
            /*
            return (
                <Button className={`open-scene-button`}
                        sx={{height: `40px`, maxWidth: `fit-content`}} color={`info`}
                        onClick={openSceneInAnimotive}>
                    Open
                </Button>
            )
            */
        } else {
            return (
                <Button className={`open-scene-button`}
                        sx={{height: `40px`, maxWidth: `fit-content`}} color={`info`}
                        onClick={webReturnSceneInfo}>
                    <Typography>Open Scene</Typography>
                </Button>
            )
        }
    }

    function checkEmptySceneName() {
        if (props.field.sceneName === "") {
            return "Untitled Scene"
        } else {
            return props.field.sceneName
        }
    }

    function UsersAreInScene() {
        return props.field.userIdsInTheScene.length > 0
    }

    function DisplayLiveSessionPanel() {
        if (UsersAreInScene()) {
            return <SessionLivePanel/>
        }
    }

    function EditComponent({callback}) {

        const [duplicatingScene, setDuplicatingScene] = useState(false);

        function ProcessSubmit(data) {
            callback(false)
            onSubmit(data)
        }

        function LeaveEditMenu() {
            reset()
            callback(false)
        }

        async function DuplicateScene(){
            setDuplicatingScene(true)
            await props.duplicateCallback(props.field.sceneKey)
            LeaveEditMenu()
            setDuplicatingScene(false)
        }

        if(duplicatingScene) {
            return <CircularProgress/>
        }

        return <Box component={"form"} display={"flex"} flexDirection={"column"} onSubmit={handleSubmit(ProcessSubmit)} >
                <TextField
                    {...register("sceneName")}
                    id="outlined-multiline-static"
                    label="Scene Name"
                    variant="outlined"
                    sx={{width: "100%"}}
                    defaultValue={props.field.sceneName}
                    error={!!errors.sceneName}
                />
                <Typography variant={"caption"} color={"error"}>{errors.sceneName?.message}</Typography>
                <Typography variant="body2" sx={{mt: 2}}>
                    Date Created: {dateCreated}
                </Typography>
                <Typography variant="body2" sx={{my: 2}}>
                    Date Modified: {dateModified}
                </Typography>
                <Box justifyContent={"space-between"} display={"flex"} alignContent={"flex-end"}>
                    <Button visible={props.isAdmin} sx={{marginRight: 2}} color={`error`} disabled={UsersAreInScene()}
                            onClick={handleDeleteCall}>Delete</Button>
                    <Button visible={props.isAdmin} type={"button"} color={`info`} disabled={UsersAreInScene()} onClick={DuplicateScene}>Duplicate</Button>
                    <Button visible={true} type={"reset"} color={`info`} onClick={LeaveEditMenu}>Cancel</Button>
                    <Button visible={props.isAdmin} color={`info`} type={"submit"}>Save</Button>
                </Box>
        </Box>
    }

    function DisplayComponent() {
        return (
            <Stack justifyContent={"space-between"} alignItems={"center"} direction={`row`} spacing={1}
                   className={"list-header"}>
                {/*<CopyTextToClipboard text={window.location.host  + "/#/link/" + sceneLink}/> */}
                <Typography sx={{paddingLeft: 2, maxWidth: "60%"}}
                            textOverflow={"hidden"}>{checkEmptySceneName()}</Typography>
                <DisplayLiveSessionPanel/>
                <SceneJoinButton/>
            </Stack>
        )
    }

    return (
        <CollapsibleCardHeading key={props.field.sceneKey} title={checkEmptySceneName()}
                                displayComponent={<DisplayComponent/>}
                                editComponent={<EditComponent/>}/>
    )
}

export default SceneSelectionCollapsable;