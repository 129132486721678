import {Box} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import Typography from "@mui/material/Typography";

export default function SessionLivePanel(){
    return (
        <Box
            display={"flex"}
            maxWidth={"75px"}
            flexDirection={"horizontal"}
            alignItems={"center"}
            bgcolor={"error.main"}
            paddingX={1}
            sx={{borderRadius: `5px`}}
        >
            <Typography
                width={"fit-content!important"}
                maxWidth={"75px"}
                variant={"body2"}
                fontSize={'16px'}
            >LIVE</Typography>
        </Box>
    )
}