import GetsDirect from "../api-calls/GetsDirect";
import {
    setCurrentProject, setInvites, setProject, setScenes, setTeamMembers, setUserInfo
} from "../features/states/ProjectFunctions";
import {store} from "../app/store";
import DeleteAPI from "../api-calls/Delete";
import {GetsCustom} from "../api-calls/GetsDirect";
import {SignOutOfClient} from "./AmplifyFunctions";
import Posts from "../api-calls/Posts";

export default async function GetUserProjects() {
    let refresh = await GetsDirect(`Projects/get-users-projects`);
    store.dispatch(setProject(refresh.data));
}

export async function GetProjectInfoFromServer(id, global = true) {
    const currentRoute = "Projects/get-project/" + id;
    try {
        let data = await GetsDirect(currentRoute);
        if (global) {
            store.dispatch(setCurrentProject(data.data));
        }
        return (data.data)
    } catch (e) {
        throw new Error("Error getting project info from server");
    }
}

export async function GetTeamInfoFromServer(id) {
    const route = "Projects/get-project-users/" + id;
    let data = await GetsDirect(route);
    data = data.data;
    store.dispatch(setTeamMembers(data));
}

export async function GetDownloadURL(){
    const route = "PresignedUrl/presigned-exec-url"
    const options = {
        method: 'get',
        headers: {
            'apikey': process.env.REACT_APP_DOWNLOADKEY
        }
    }
    const response = await GetsCustom(route, options)
    return response.presignedUrl;

}

export async function GetScenesFromServer(id) {
    let userScenesString = "Scenes/get-project-scenes/" + id;

    try {
        let data = await GetsDirect(userScenesString);

        if(data.code === 200){
            data.data.forEach((scene) => {
                let prefix = id + "/Scenes/";
                scene.sceneKey = scene.sceneKey.replace(prefix, "");
            });

            store.dispatch(setScenes(data.data));
            return data.data;
        }
        else if(data.code === 404){
            store.dispatch(setScenes(null));
            throw new Error("No scenes found")
        }

    } catch (e) {
         if(e.message === 'No scenes found'){
            return null;
        }
        else{
            throw new Error("Error getting scenes from server");
        }
    }
}

export async function GetInvitesFromServer() {
    const response = await GetsDirect("UserInvite/my-invites");
    store.dispatch(setInvites(response.data));
}

export async function GetUserInfoFromServer() {
    const route = "User/GetUserInfo";
    const response = await GetsDirect(route);
    store.dispatch(setUserInfo(response.data));
    return response.data;
}

export async function LeaveProject(id) {
    const route = "Projects/leave-project"
    const body = {
        projectId: id,
    }
    const response = await DeleteAPI(body, route)
}

export async function UpdateProjectName(id, projectName){
    const route = "Projects/update-project-name"
    const body = {
        projectId: id,
        projectName: projectName
    }
    const response = await Posts(body,route)
    return response;

}

export async function DeleteProject(id, projectName) {

    if(window.vuplex){
        const project = {
            type: 'projectInfo',
            value: ""
        }
        window.vuplex.postMessage(project)
    }


    const route = "Projects/delete-project"
    const body = {
        projectId: id, projectTitle: projectName
    }

    await new Promise(r => setTimeout(r, 2000));
    const response = await DeleteAPI(body, route)
    return response;
}

export async function DeleteBulkFunction(projectList){
    projectList.forEach((project) => {
        DeleteProjectBulk(project.projectId, project.projectName)
    })

    function DeleteProjectBulk(id, projectName){
        const route = "Projects/delete-project"
        const body = {
            projectId: id, projectTitle: projectName
        }
        DeleteAPI(body, route)
    }
}

export async function GetSubscriptionInfo(){
    const route = "Subscription/get-subscription-info"
    const response = await GetsDirect(route)
    return response.data
}
