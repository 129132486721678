import React, {useEffect} from "react";
import {
    Box,
    DialogActions, DialogContentText, Stack,
    TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import * as Yup from 'yup';
import Posts from "../../api-calls/Posts";
import CreationCollapsable from "../Structural Components/CreationCollapsable";
import {GetScenesFromServer} from "../../functions/ServerCallFunctions";

const CollapsableContent = ({errors, register, setFocus, handleCloseCallback}) => {
    useEffect(() => {
        setFocus("sceneTitle")
    }, [setFocus])

    return <>
        <Box
            sx={{display: 'flex', flexGrow: 1, flexDirection: 'column', alignItems: 'left'}}
        >
            <DialogContentText>Create a new Scene in this Project</DialogContentText>
            <TextField
                autoFocus={true}
                id="sceneTitle"
                hiddenLabel={true}
                label={"Scene Name"}
                name="sceneTitle"
                {...register("sceneTitle", {required: "Scene Name is required"})}
                size={"small"}
                sx={{marginY: 1, flexGrow: 1}}
                error={!!errors.sceneTitle}
            />
            <Typography variant={"caption"} color={"error"}>{errors.sceneTitle?.message}</Typography>
            <Stack direction={"row"} justifyContent={"flex-end"}>
                <DialogActions>
                    <Button variant={"contained"} color={"secondary"} onClick={handleCloseCallback}>Cancel</Button>
                </DialogActions>
                <DialogActions>
                    <Button variant={"contained"} color={"secondary"} type={`submit`}>Create</Button>
                </DialogActions>
            </Stack>
        </Box>
    </>
}

export default function CreateSceneFormComponent(props) {

    const {scenesInProject} = props;

    function checkIfInputIsDuplicate() {
        return this.test("isUniqueName", "Scene Already Exists", function (value) {

            if (scenesInProject === null) {
                return true;
            }

            // Check if the name is already in the list of scenes
            for (let i = 0; i < scenesInProject.length; i++) {
                if (scenesInProject[i].sceneName === value) {
                    return false;
                }
            }
            return true;
        });
    }

    Yup.addMethod(Yup.string, "checkIfInputIsDuplicate", checkIfInputIsDuplicate);

    const ValidationSchema = Yup.object().shape({
        sceneTitle: Yup.string().required("Scene name is required").max(50, "Scene Name is too long").checkIfInputIsDuplicate(scenesInProject, "Scene name is already in use"),
    });

    const resetValues = {
        sceneTitle: '',
    };

    async function handleAddNewSceneAndRefresh(e) {
        const sceneData = {
            projectID: props.projectID,
            sceneName: e.sceneTitle,
        }
        let response = await Posts(sceneData, "Scenes/create-project-scene")
        if (response.code === 200) {
            GetScenesFromServer(props.projectID);
        }
    }


    return <CreationCollapsable
        props={props}
        ValidationSchema={ValidationSchema}
        resetValues={resetValues}
        callback={handleAddNewSceneAndRefresh}
        title={"Create a new scene"}>
        <CollapsableContent/>
    </CreationCollapsable>
}