import React, {useRef} from "react";
import {register} from 'swiper/element/bundle';
import {Box, Button, Dialog, DialogContent, DialogTitle, IconButton, MenuItem, MenuList} from "@mui/material";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

register();

export const InfiniteScroll = ({children}) => {
    const swiperElRef = useRef(null);

    function forwardSlide() {
        swiperElRef.current.swiper.slideNext();
    }

    function backSlide() {
        swiperElRef.current.swiper.slidePrev();
    }

    return (<Box className={'inner-box'} sx={{height: "80vh"}}>
            <Button sx={{display: "flex", width: "100%"}} onClick={backSlide}>
                <KeyboardArrowUp color={"primary"}/>
            </Button>
            <swiper-container
                ref={swiperElRef}
                direction={"vertical"}
                slidesPerView={"auto"}
                pagination={{clickable: true}}
                mousewheel={true}
            >
                {children}
            </swiper-container>
            <Button sx={{display: "flex", width: "100%", justifyContent: "center"}} onClick={forwardSlide}>
                <KeyboardArrowDown color={"primary"}/>
            </Button>
        </Box>

    )
}

export const SlideShowContent = (props) => {
    return (
        <Box className={"vertical-slider"} sx={{alignSelf: "center"}}>
            {props.children}
        </Box>
    )
}

export default function InfiniteScrollDialog({open, onClose, children, slideData}) {

    const BootstrapDialogTitle = (props) => {
        const {children, onClose, ...other} = props;

        return (<DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (<IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>) : null}
        </DialogTitle>);
    };

    return (<Dialog
        className={"landing-page-dialog"}
        open={open}
        onClose={onClose}
        PaperProps={{
            sx: {
                width: '100%', height: '100%', bgcolor: 'background.paper', boxShadow: 24, m: 0,
            },
        }}
    >
        <BootstrapDialogTitle onClose={onClose}>
            {slideData.title}
        </BootstrapDialogTitle>
        <DialogContent sx={{p: 2}}>
            {/*<MenuList
                sx={{
                    display: "flex",
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                {
                    slideData.slideData.map((item, index) => {
                        return (<MenuItem key={index} onClick={item.onClick} >
                            <Link sx={{color:"white"}} to={'/#slide-' + item.title}>{item.title}</Link>
                        </MenuItem>)
                    })
                }
            </MenuList> */}
            {children}
        </DialogContent>
    </Dialog>)
}