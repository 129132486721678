import {Box, Stack} from "@mui/material"
import Typography from "@mui/material/Typography";
import React from "react";
import {AnimotiveSwitch} from "../StyledComponents/StyledComponents";

export default function AudioQualityToggle({audioQuality, callback}) {

    function changeAudioQualityToTrueOrFalse() {
        return audioQuality === 2;
    }

    let defaultState = changeAudioQualityToTrueOrFalse();

    function testCallback(e) {
        callback(e.target.checked);
    }

    function getProjectType() {

        return <Stack direction="row" spacing={1} alignItems="center">
            <Typography
                variant={"overline"}>MP3</Typography>
            <AnimotiveSwitch
                defaultChecked={defaultState}
                onChange={testCallback}
                inputProps={{'aria-label': 'ant design'}}
            />
            <Typography variant={"overline"}>Wav</Typography>
        </Stack>
    }

    return (
        <Box>
            {getProjectType()}
        </Box>
    )
}