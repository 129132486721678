import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import React from "react";

export default function ErrorModal(props) {
    const handleClose = () => {
        props.closeCallback();
    };

    let settings = props.settings;

    return <Dialog open={settings.open} onClose={handleClose}>
        <DialogTitle>{settings.title}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {settings.content}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Close
            </Button>
        </DialogActions>
    </Dialog>
}