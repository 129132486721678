import {configureStore} from "@reduxjs/toolkit";
import projectReducer, {
    projectInfoSlice,
    unitySlice,
    invitesSlice,
    navigationSlice, checkoutSlice
} from "../features/states/ProjectFunctions";
import {loginSlice} from "../features/states/ProjectFunctions";

export const store = configureStore({
    reducer: {
        projectList: projectReducer,
        loginToken: loginSlice.reducer,
        OpenInUnity: unitySlice.reducer,
        projectInformation: projectInfoSlice.reducer,
        invitesList: invitesSlice.reducer,
        navigation: navigationSlice.reducer,
    },
});
