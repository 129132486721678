import React, {useEffect} from "react";
import {
    selectJWTToken, selectTokens, setApplicationVersion,
    setUnity
} from "../../features/states/ProjectFunctions";
import {Auth} from "aws-amplify";
import {useDispatch, useSelector} from "react-redux";
import {Box} from "@mui/material";
import {getNewTokenValueFromAWS, getTokenValueFromAWS, SignOutOfClient} from "../../functions/AmplifyFunctions";
import {useNavigate} from "react-router-dom";

export function SendNullToken() {
    if (window.vuplex) {
        const token = {
            type: 'accessToken',
            value: ""
        }
        window.vuplex.postMessage(token)

        const project = {
            type: 'projectInfo',
            value: ""
        }
        window.vuplex.postMessage(project)
    }
}

export function SendMessageToUnity(type, value) {
    if (window.vuplex) {
        const params = {
            type: type,
            value: value
        }
        window.vuplex.postMessage(params)
    }
}

export function UnityLink(URL) {
    if (window.vuplex) {
        const params = {
            type: 'webLink',
            value: URL
        }
        window.vuplex.postMessage(params)
    } else {
        window.open(URL, "_blank", 'noreferrer')
    }
}

export async function OpenAssetConfig(){
    await getNewTokenValueFromAWS()
    const params = {
        type: 'openCommands',
        value: 'AssetConfig'
    }
    window.vuplex.postMessage(params)
}

export default function UnityCommandsContext(props) {

    const dispatch = useDispatch()
    const tokens = useSelector(selectTokens);
    const navigate = useNavigate();

    window.GetRefreshToken = () => {
        return tokens.refreshToken;
    }

    window.GetAccessToken = () => {
        return tokens.accessToken;
    }

    window.GetNewAccessToken = () => {
        if(window.vuplex){
            getNewTokenValueFromAWS()
        }
    }

    useEffect(() => {
        getTokenValue();
    }, [props.userloggedIn])

    window.GetTokenValues = () => {
        getTokenValue();
    }

    async function getTokenValue() {
        getTokenValueFromAWS()
    }

    useEffect(() => {
        if (tokens.idToken !== null) {
            /* SendJWTToken(); */
        }
    }, [tokens.idToken])

    useEffect(() => {
         if (tokens.accessToken !== null) {
            SendAccessToken();
        }
    }, [tokens.accessToken])

    useEffect(() => {
        if (tokens.refreshToken !== null) {
            SendRefreshToken();
        }
    }, [tokens.refreshToken])

    function SendRefreshToken() {
        if (window.vuplex && tokens.refreshToken !== null) {
            const params = {
                type: 'refreshToken',
                value: tokens.refreshToken
            }
            window.vuplex.postMessage(params)
        }
    }

    function SendAccessToken() {
        if (window.vuplex && tokens !== null && tokens.accessToken !== undefined) {
            const params = {
                type: 'accessToken',
                value: tokens.accessToken
            }
            window.vuplex.postMessage(params)
        }
    }


    window.FederatedLogin = (token, username) => {
        Auth.federatedSignIn(
            'cognito-idp.eu-west-2.amazonaws.com/eu-west-2_ySe4fMEGL',
            {
                token: token,
                expires_at: 3600000
            },
            {username}).then(() => {
            window.location.reload(false);
        })
    }

    useEffect(() => {
        if (window.vuplex) {
            setUpStartup();
        } else {
            window.addEventListener('vuplexready', setUpStartup);
        }
    }, [])

    function setUpStartup() {
        dispatch(setUnity(true))
        const params = {
            type: 'webLoad',
            value: "Hello World"
        }
        window.vuplex.postMessage(params)

        window.vuplex.addEventListener('message', function (event) {
            let json = event.data;
            let obj = JSON.parse(json);

            if (obj.type === 'loginToken') {
                window.FederatedLogin(obj.token, obj.userEmail)
            }

            if(obj.type === 'applicationVersion'){
                dispatch(setApplicationVersion(obj.value))
            }

            if(obj.type === 'logoutUser'){
                SignOutOfClient()
                navigate("/")
            }
        });
    }

    const {children} = props;

    return (
        <Box>
            {children}
        </Box>
    );
};