import {Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

export default function InformationModal({informationText, title, handleClose, open}) {

    const BootstrapDialogTitle = (props) => {
        const {children, onClose, ...other} = props;

        return (
            <DialogTitle sx={{m: 0, p: 2}} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    return (<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        sx={{minWidth: {sm:"100%", md:"30vw"}}}>
        <Box sx={{m: 2, minWidth: {sm:"100%", md:500}}}/>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            {title}
        </BootstrapDialogTitle>
        {handleClose ? (
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon/>
            </IconButton>
        ) : null}
        <DialogContent>
            <DialogContentText style={{whiteSpace: "pre-line"}}>
                {informationText}
            </DialogContentText>
        </DialogContent>
        <Button onClick={handleClose} variant={"contained"} color="secondary">
            Close
        </Button>
    </Dialog>)
}