import { Button as MuiButton, ButtonProps } from "@mui/material";
import { FC } from "react";

// Add your custom props here
type MyButtonProps = {
    //set default value to true
    visible?: boolean;
}

const Button: FC<ButtonProps & MyButtonProps > = ({ visible = true ,children, variant, ...props }) => {
    if(visible) {
        return (
            <MuiButton variant={variant} {...props} color="inherit">
                {children}
            </MuiButton>
        );
    }
};

export default Button;