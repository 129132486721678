import {Alert, Button, Snackbar} from "@mui/material";
import {useState} from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Typography from "@mui/material/Typography";

export function copyToClipboardRoot(text) {
    if(window.vuplex) {
        const params = {
            type: 'clipboardText',
            value: text
        }
        window.vuplex.postMessage(params);
    }
    else {
        navigator.clipboard.writeText(text);
    }
}

export default function CopyTextToClipboard(props){

    const [copied, setCopied] = useState(false);

    function copyToClipboard(){
        copyToClipboardRoot(props.text);
        setCopied(true);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setCopied(false);
      };

    return (
        <div>
        <Button variant={"text"} color={"info"} onClick={() => copyToClipboard()}>
            Copy Link
        </Button>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                severity="success"
                open={copied}
                autoHideDuration={1200}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Copied to Clipboard
                </Alert>
            </Snackbar>
        </div>
    )
}

export function CopyTextToClipboardIcon(props) {
    const [copied, setCopied] = useState(false);

    function copyToClipboard(){
        copyToClipboardRoot(props.text);
        setCopied(true);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setCopied(false);
    };

    return (
        <div>
            <Button variant={"text"} color={"info"} onClick={() => copyToClipboard()}>
                <ContentCopyIcon color={"info"}/>
                <Typography>Copy to Clipboard</Typography>
            </Button>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                severity="success"
                open={copied}
                autoHideDuration={1200}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Copied to Clipboard
                </Alert>
            </Snackbar>
        </div>
    )
}