import Button from "@mui/material/Button";
import React from "react";
import {Link as RouterLink} from "react-router-dom";

export default function UpgradeToProButton() {
    return (
        <Button
            variant={"contained"}
            sx={{backgroundColor: "#2F80ED", minWidth:"140px", color: "white"}}
            component={RouterLink} to={"/checkout/"}
        >
            Upgrade to PRO
        </Button>
    )
}