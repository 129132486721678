export function Youtube(){
    return (
        <div>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PL1800E1EFCA1EABE3&rel=0"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen></iframe>
        </div>
    )
}
