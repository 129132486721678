import {Stack} from "@mui/material";
import React from "react";
import {useNavigate} from "react-router-dom";
import {SignOutOfClient} from "../functions/AmplifyFunctions";
import {DiscordButton} from "./DiscordButton";
import {HelpButton} from "./HelpButton";
import MyAccountButton from "./MyAccountButton";

function MetaAccountPanels() {

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    async function signOutChain() {
        SignOutOfClient();
        navigate(`/`);
    }

    return (
        <Stack
            spacing={2}
            direction="row"
            sx={{
            margin: 2,
            display: `flex`,
            alignContent: `center`,
            alignItems: `center`,
        }}
        >
            <DiscordButton/>
            <HelpButton/>
            <MyAccountButton/>
        </Stack>
    )
}

export default MetaAccountPanels;