export function sortArrayAlphabetically(array){
    array.sort(function(a, b){
        let nameA=a.name.toLowerCase(), nameB=b.name.toLowerCase()
        if (nameA < nameB) //sort string ascending
            return -1
        if (nameA > nameB)
            return 1
        return 0 //default return value (no sorting)
    })
}

export function checkIfStringIsAlphaNumeric(str){
    return str.match(/^[a-z0-9._]+$/i) !== null
}

export function updateBackgroundColour(color){
    document.body.style.backgroundColor = color;
}

export function AddSpaceToCamelCaseString(string) {
    return string.replace(/([A-Z])/g, ' $1').trim()
}