export const projectSortOptions = [
    {value:`name`, displayValue: `Name ▼`},
    {value: `nameInverse`, displayValue: `Name ▲`},
    {value: `dateModified`, displayValue: `Date Modified ▼`},
    {value: `dateModifiedInverse`, displayValue: `Date Modified ▲`},
    {value: `dateCreated`, displayValue: `Date Created ▼`},
    {value: `dateCreatedInverse`, displayValue: `Date  Created ▲`}]

export function SortProjectsByMethod(array, sortBy, valueName){
array.sort((a, b) => {
        if (sortBy === 'name') {
            return a[valueName].localeCompare(b[valueName]);
        } else if (sortBy === `nameInverse`) {
            return b[valueName].localeCompare(a[valueName]);
        } else if (sortBy === 'dateModified') {
            return new Date(a.updatedAt) - new Date(b.updatedAt);
        }  else if (sortBy === `dateModifiedInverse`) {
            return new Date(b.updatedAt) - new Date(a.updatedAt);
        }  else if (sortBy === 'dateCreated') {
            return new Date(a.createdAt) - new Date(b.createdAt);
        } else if (sortBy === `dateCreatedInverse`) {
            return new Date(b.createdAt) - new Date(a.createdAt);
        } else {
            return a[valueName].localeCompare(b[valueName]);
        }
    });
}