import  {
    AnimotiveDownloadLatestButton
} from "../components/Nav Bar Components/AnimotiveVersionCheck";
import {Box, Divider} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";
import {selectApplicationVersionInfo} from "../features/states/ProjectFunctions";

export default function AnimotiveUpdatePage(){

    let downloadLink = useSelector(selectApplicationVersionInfo).downloadLink;

    return (
        <Box
            className={"centre-box"}
            sx={{
                alignCenter: `center`,
                alignItems: `center`,
                display: `flex`,
                minHeight: `90vh`,
                flexDirection: `column`,
                maxWidth: '600px',
            }}>
            <h1>Animotive Update Available</h1>
            <Typography flexWrap={true}>Please download the latest version of Animotive to continue using the app.</Typography>
            <Divider sx={{paddingY:2}}/>
            <AnimotiveDownloadLatestButton downloadLink={downloadLink}/>
        </Box>
    )
}