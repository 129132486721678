import './App.scss'
import {Header} from "./components/Navigation";
import {Route, Routes, useNavigate} from "react-router-dom";
import {Box, CssBaseline, ThemeProvider} from "@mui/material";
import React, {useEffect} from "react";
import ProjectPage from "./pages/ProjectPage";
import Authentication, {ConfirmationScreen} from "./pages/Authentication";
import {theme} from "./themes/customTheme";
import {Amplify, Auth} from "aws-amplify";
import awsconfig from "./aws-exports";
import LaunchPage from "./pages/LaunchPage";
import DownloadPage from "./pages/DownloadPage";
import {useDispatch, useSelector} from "react-redux";
import {
    selectLoginState,
    setEmailAddress,
    setUsername,
    setLoginState,
    selectRedirectionPage,
    selectUsername, selectApplicationVersion, selectIsLatestVersion, setIsLatestVersion
} from "./features/states/ProjectFunctions";
import UnityCommandsContext from "./components/Structural Components/UnityCommandsContext";
import ProjectsInformationPage from "./components/ProjectList";
import {InviteList} from "./components/InviteList";
import {GetInvitesFromServer, GetUserInfoFromServer} from "./functions/ServerCallFunctions";
import Link from "./pages/Link";
import Survey from "./pages/Survey";
import AITestPage from "./features/AI/SkyboxGeneration";
import CheckoutPaddle from "./pages/CheckoutPaddle";
import {InfiniteScroll} from "./components/Onboarding Components/InfiniteScroller";
import {Youtube} from "./pages/YoutubePlaylist";
import UserAccountSettings from "./pages/UserAccountSettings";
import {useTrackingCode} from "react-hubspot-tracking-code-hook";
import ScriptGenerator from "./pages/ScriptGenerator";
import {store} from "./app/store";
import {EnterpriseContactForm} from "./pages/EnterpriseContactUs";
import {checkApplicationVersion} from "./components/Nav Bar Components/AnimotiveVersionCheck";
import AnimotiveUpdatePage from "./pages/AnimotiveUpdatePage";
import LandingPageV2 from "./pages/LandingPageV2";

Amplify.configure({
    ...awsconfig, Auth: {
        identityPoolId: `eu-west-2:16148429-9897-4553-9c28-74931e1f0c83`
    }, oauth: {
        ...awsconfig.oauth,
        redirectSignIn: 'http://localhost:3000/',
        redirectSignOut: 'http://localhost:3000/',
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
});

export async function getLoginInformation() {
    try {
        let response = await Auth.currentAuthenticatedUser();
        store.dispatch(setLoginState(true))
        store.dispatch(setEmailAddress(response.attributes.email))
    } catch (err) {
        store.dispatch(setLoginState(false));
    }
}

const DisplayForUsersNotLoggedIn = () => {
    return (<>
        <Box display={"flex"} flexDirection={"column"} sx={{width: "100%", height:"100vh"}} >
            <Header sx={{background: 404040, height: 'fit-content'}}/>
            <Box
                component="main"
                sx={{
                    flexGrow: 1, width: {xs: `100%`}, justifyContent: "center", display: "flex", height: "100%"
                }}
            >
                <Routes>
                    <Route path="*" element={<Authentication Screen={"signIn"}/>}/>
                    <Route path={"/register"} element={<Authentication Screen={"signUp"}/>}/>
                    <Route path={"/confirm"} element={<ConfirmationScreen/> }/>
                    <Route path={"download"} element={<DownloadPage/>}/>
                    <Route path={"/link/:sessionId"} element={<Link/>}/>
                    <Route path={"/scroll"} element={<InfiniteScroll/>}/>
                    <Route path={"/checkout"} element={<Authentication Screen={"signIn"} redirection={"checkout"}/>}/>
                    <Route path={"/enterprise-contact-form"} element={<EnterpriseContactForm/>}/>
                </Routes>
            </Box>
        </Box>
    </>)
}

const MainDisplay = () => {
    return (<Box display={"flex"} flexDirection={"column"} width={"100%"} height={"100vh"}>
        <Header sx={{background: 404040, height: 'fit-content'}}/>
        <Box
            component="main"
            sx={{
                flexGrow: 1, maxWidth:"100%" , width: {xs: `100%`}, justifyContent: "center", display: "flex"
            }}
        >
            <Routes>
                <Route path="*" element={<MainBody/>}/>
                <Route path="login" element={<Authentication/>}/>
                <Route path="settings" element={<UserAccountSettings/>}/>
                <Route path="launch-animotive" element={<LaunchPage/>}/>
                <Route path={"download"} element={<DownloadPage/>}/>
                <Route path={"checkout/"} element={<CheckoutPaddle/>}/>
                <Route path={"link/:sessionId"} element={<Link/>}/>
                <Route path={"/survey"} element={<Survey/>}/>
                <Route path={"/scroll"} element={<InfiniteScroll/>}/>
                <Route path={"/script"} element={<ScriptGenerator/>}/>
                <Route path={"confirm"} element={<ConfirmationScreen/>}/>
            </Routes>
        </Box>
    </Box>)
}

function MainBody() {
    return <Box padding={2}>
        <Routes>
            <Route path={"/"} element={<LandingPageV2/>}/>
            <Route path="/projects" element={<ProjectsInformationPage/>}/>
            <Route path="/projects/:id" element={<ProjectPage/>}/>
            <Route path={"/testPage"} element={<AITestPage/>}/>
            <Route path={"/help"} element={<Youtube/>}/>
            <Route path={"/invitations"} element={<InviteList/>}/>
            <Route path={"/enterprise-contact-form"} element={<EnterpriseContactForm/>}/>
        </Routes>
    </Box>;
}

function App() {

    const dispatch = useDispatch()
    const userLoggedIn = useSelector(selectLoginState)

    const redirectionInPlace = useSelector(selectRedirectionPage)
    const userNames = useSelector(selectUsername)

    const applicationVersion = useSelector(selectApplicationVersion)
    const isLatestVersion = useSelector(selectIsLatestVersion)

    const {setIdentity} = useTrackingCode();

    useEffect(() => {
        getLoginInformation();
    }, []);

    useEffect(() => {
        SetLoggedInValues()
    }, [userLoggedIn])

    useEffect(() => {
        checkIfLatestVersion()
    }, [applicationVersion])

    async function checkIfLatestVersion(){
        let response = await checkApplicationVersion();
        dispatch(setIsLatestVersion(response))
    }

    let navigate = useNavigate()

    useEffect(() => {
        if(userNames !== null){
            if(redirectionInPlace !== null){
                return navigate(redirectionInPlace)
            }
        }
    },[userNames])

    async function SetLoggedInValues(){
        if (userLoggedIn) {
            GetInvitesFromServer()
            const response = await GetUserInfoFromServer()
            setIdentity(response.email)
            dispatch(setUsername(response.userName))
        }
    }

    // localhost:3000

    if (userLoggedIn === undefined) {
        return <></>
    } else {
        return <Box>
            <ThemeProvider theme={theme}>
                <CssBaseline enableColorScheme/>
                <UnityCommandsContext userloggedIn={userLoggedIn}>
                    <Box
                        sx={{
                            display: 'flex', fontFamily: 'Source Sans Pro', justifyContent: 'center'
                        }}>
                        {isLatestVersion ?
                        userLoggedIn ? <MainDisplay/> : <DisplayForUsersNotLoggedIn/>
                        : <AnimotiveUpdatePage/>
                        }
                    </Box>
                </UnityCommandsContext>
            </ThemeProvider>
        </Box>
    }
}

export default App;
