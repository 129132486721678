import {createTheme, ThemeOptions} from "@mui/material";

let tempTheme = createTheme();

export const themeOptions: ThemeOptions = {
    palette: {
        type: 'dark',
        primary: {
            main: '#ffffff',
            contrastText: 'rgba(255,255,255,0.87)',
        },
        secondary: {
            main: '#333333',
        },
        background: {
            default: '#7F7F7F',
            paper: '#7F7F7F',
        },
        text: {
            primary: '#FFFFFF',
            secondary: '#FFFFFF',
            info: '#6d9cb4',
            disabled: '#858585',
            hint: 'rgba(105,19,19,0.38)',
        },
        info: {
            main: '#99d4ff',
            dark: '#2f80ed',
        },
        divider: '#ffffff',
    },
    typography: {
        allVariants: {
            fontFamily: 'Source Sans Pro !important',
        },
        button: {
            textTransform: 'capitalize',
        },
        h3: {
            fontSize: "1.5rem",
        },
        h5: {
            fontWeight: 600,
        },
        h6: {
            fontWeight: 600,
        },
        overline: {
            fontSize: '12pt',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled": {
                        color: '#FFFFFF',
                        opacity: 0.5,
                    },
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: '#FF0000',
                    backgroundColor: '#FFFFFF',
                    fontWeight: 700,
                    textTransform: 'uppercase',
                },
            },
        },
    },
    overrides: {
        MuiCheckbox: {
            root: {
                color: '#5899F0',
            },
        },
        MuiToolbar: {
            root: {
                [tempTheme.breakpoints.down('xl')]: {
                    minHeight: '48px',
                },
                minHeight: 10,
            },
        },
        MuiAppBar: {
            root: {
                [tempTheme.breakpoints.down('xl')]: {
                    minHeight: '48px',
                },
                minHeight: 10,
            },
        },
        MuiFormControl: {
            select: {
                [tempTheme.breakpoints.between('xs', 'sm')]: {
                    marginTop: 10,
                },
            },
        },
    },
};


export const theme = createTheme(themeOptions)
