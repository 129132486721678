import {
    Box,
    Divider,
    Drawer,
    List,
    ListItemText,
    Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import React from "react";
import SystemRequirementsDrawer from "../components/SystemRequirementsDrawer";
import {GetDownloadURL} from "../functions/ServerCallFunctions";
import {UnityLink} from "../components/Structural Components/UnityCommandsContext";
import '../FrontEndStyling.scss';
import {ReactComponent as WindowsLogo} from "../img/microsoft-windows-22.svg";

export function GettingStartedPage() {
    return <Stack direction={"column"} width={"100%"} paddingBottom={3} maxWidth={"50vw"}>
        <DownloadPageHeader/>
        <DownloadPageBody/>
    </Stack>;
}

export default function DownloadPage() {

    async function OpenDownloadLink() {
        let url = await GetDownloadURL();
        window.open(url, "_blank");
    }

    return (
        <Box
            className={"content"}
            sx={{backgroundColor: "#868B9A", width: "100%", height: "fit-content"}}
        >
            <Stack className={"inner-box"} display={"flex"} flexDirection={"column"} spacing={2} alignItems={"center"}
                   textAlign={"center"} maxWidth={"360px"}>
                <img src={'./img/DownloadPage/Animotive_icon1.png'} alt={'Animotive Icon'}
                     className={"animotive-icon-box"}/>
                <Button
                    onClick={OpenDownloadLink}
                    variant="contained"
                    className={"download-button"}
                    sx={{
                        backgroundColor: "#3F424B",
                        width: "100%",
                        fontWeight: 700,
                        textTransform: "uppercase",
                        borderRadius: "48px",
                        border: "2px solid #FFFFFF",
                        marginTop: "20px"
                    }}
                >
                    Download Animotive
                </Button>
                <Stack direction={"row"} justifyContent={"space-between"} width={"80%"} alignItems={"center"}>
                    <Stack spacing={1} direction={"row"} marginBottom={2}>
                        <Typography>
                            Windows Only
                        </Typography>
                        <WindowsLogo style={{width: "20px", height: "20px"}}/>
                    </Stack>
                    <SystemRequirementsDrawer/>
                </Stack>
                <Typography>
                    <b>ATTENTION VR HEADSET USERS:</b> Follow the steps below when using ANIMOTIVE for the first time.
                </Typography>
                <GettingStartedPage/>
            </Stack>
        </Box>
    )
}

function DownloadPageHeader() {
    return (
        <Stack direction={"column"} className={"download-page-header"} spacing={2}>
            <Typography>QUICK VR PC SET UP GUIDE (2 mns)</Typography>
            <Stack direction={"row"} justifyContent={"space-evenly"} width={"100%"}>
                <img src={'./img/DownloadPage/MetaLogo.png'} alt={'Meta Logo'}/>
                <img src={'./img/DownloadPage/ValveLogo.png'} alt={'SteamVR Logo'}/>
                <img src={'./img/DownloadPage/ViveLogo.png'} alt={'Vive Logo'}/>
                <img src={'./img/DownloadPage/PicoLogo.png'} alt={'Pico Logo'}/>
            </Stack>
        </Stack>
    )
}

function DownloadPageBody() {
    return (
        <Stack direction={"column"} className={"download-page-body"}>
            <OculusInformationBlock/>
            <Divider sx={{width:"100%", borderWidth:.5, borderColor:`#000000`,marginY:1}} />
            <SteamInformationBlock/>
            <Divider sx={{width:"100%", borderWidth:.5, borderColor:`#000000`,marginY:1}} />
            <FooterBox/>
        </Stack>
    )
}

function OculusInformationBlock() {
    return <Box>
        <img style={{marginLeft: "-16px"}} src={'./img/DownloadPage/Meta_lockup_positive_primary_RGB.png'}
             alt={'Meta Logo'}/>
        <Typography>For all <b>Meta Quest headsets - 1, 2, 3 & PRO</b> (link only)<br/>
            <span className={"unity-link"}
                  onClick={() => UnityLink("https://www.meta.com/en-gb/help/quest/articles/headsets-and-accessories/oculus-rift-s/install-app-for-link/")}> Install</span> <b>META QUEST</b></Typography>
        <List sx={{listStyle: "decimal", width: "100%", pl: 4}}>
            <ListItemText sx={{display: "list-item"}}>Open the <b>Meta Quest Link</b> app on your PC</ListItemText>
            <ListItemText sx={{display: "list-item"}}>Select <b>Settings</b> in the left menu</ListItemText>
            <ListItemText sx={{display: "list-item"}}>Select the <b>General</b> tab</ListItemText>
            <ListItemText sx={{display: "list-item"}}>Next to <b>Unknown Sources</b>, switch the toggle and
                confirm to allow content from unknown sources</ListItemText>
            <ListItemText sx={{display: "list-item"}}>Lastly press the button to set <b>Oculus</b> as your <b>default
                OpenXR Runtime</b></ListItemText>
        </List>
        <VideoDisplayDrawer sx={{alignSelf: "center"}} title={"Watch the Oculus Install video"} url={"3zi_mLqgIBA"}/>
    </Box>
}

function SteamInformationBlock() {
    return <Box width={"100%"}>
        <img style={{marginLeft: "4px"}} src={'./img/DownloadPage/SteamVRLogo.png'}
             alt={'Meta Logo'}/>
        <Typography>For <b>HTC Vive, VALVE Index</b> and <b>PICO headsets</b><br/>
            <span className={"unity-link"}
                  onClick={() => UnityLink("https://store.steampowered.com/app/250820/SteamVR/")}>Install</span> <b>SteamVR*</b></Typography>
        <List sx={{listStyle: "decimal", width: "100%", pl: 4}}>
            <ListItemText sx={{display: "list-item"}}>Open the <b>Steam VR</b> app on your Desktop</ListItemText>
            <ListItemText sx={{display: "list-item"}}>Select the Burger menu</ListItemText>
            <ListItemText sx={{display: "list-item"}}>Select <b>Open XR</b></ListItemText>
            <ListItemText sx={{display: "list-item"}}>Set <b>Steam VR</b> as <b>OpenXR Runtime</b></ListItemText>
        </List>
        <VideoDisplayDrawer sx={{alignSelf: "center"}} title={"Watch the SteamVR Install video"} url={"Q-9viFEXAVU"}/>
        <Box display={"flex"} alignItems={"center"}>
            <Typography fontStyle={"italic"}>*You will need to have registered a Steam account.</Typography>
        </Box>
    </Box>
}

function FooterBox(){
    return <Box sx={{paddingY:2}}>
        <Typography>For more info, Go to the <span className={"unity-link"} onClick={() => UnityLink("https://animotive.gitbook.io/animotive-documentation/")}><b>Animotive</b> Documentation</span></Typography>
    </Box>
}

function VideoDisplayDrawer(props) {
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setDrawerOpen(open);
    };

    return (<Box style={{color: '#868B9A'}} sx={{alignItems: "center", justifyContent: "center", display: "flex"}}>
            <Button variant={"text"} sx={{textDecoration: "underline", color: "#000000"}}
                    onClick={() => setDrawerOpen(true)}>{props.title}</Button>
            <Drawer
                anchor={"bottom"}
                open={drawerOpen}
                onClose={toggleDrawer(false)}
            >
                <Box sx={{width: '100%', backgroundColor: "#868B9A", display: 'flex', justifyContent: 'center'}}>
                    <Box sx={{
                        display: `flex`,
                        alignSelf: `center`,
                        flexDirection: `column`,
                        padding: `20px`,
                        width: "80%"
                    }}>
                        <Box className={"video-responsive"}>
                            <iframe
                                width="853"
                                height="480"
                                src={`https://www.youtube.com/embed/${props.url}`}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Embedded youtube"
                            />
                        </Box>
                    </Box>
                </Box>
            </Drawer>
        </Box>

    )
}