import {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {Button, MenuItem, Select, Stack, TextField} from "@mui/material";

const apiKey = ""
export default function AITestPage() {

    const [generatorList, setGeneratorList] = useState()
    const [styleList, setStyleList] = useState()
    const [style, setStyle] = useState()
    const [imageRequest, setImageRequest] = useState()
    const [imageURL, setImageURL] = useState()
    const [status, setStatus] = useState()

    const [prompt, setPrompt] = useState()

    async function getGeneratorList() {

        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        }
        return await fetch("https://backend.blockadelabs.com/api/v1/generators?api_key=" + apiKey, requestOptions)
            .then(function (response) {
                return response.json();
            }).then(function (data) {
                return data;
            })
    }

    async function getSkyboxGenerator() {
        return fetch("https://backend.blockadelabs.com/api/v1/skybox?api_key=" + apiKey)
            .then(response => response.json())
            .then((data) => {
                //remove last 5 entries
                let array = Object.entries(data).map(([key, value]) => ({value}))
                console.log(array)
                array = array.splice(0, array.length - 5)
                return array
            })
    }

    useEffect(() => {
        UseSkyboxGeneration()
    }, [])

    useEffect(() => {
        if(imageRequest !== undefined){
            AsyncGetImage()
        }
    }, [imageRequest])

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

    async function AsyncGetImage(){
        await sleep(750);
        let data = await GetImageData()
        if(data.request.status === "complete"){
            setImageURL(data.request.file_url)
        }
        else AsyncGetImage()
    }

    async function UseSkyboxGeneration() {
        const test = await getGeneratorList()
        const skyboxList = await getSkyboxGenerator()
        console.log(skyboxList)
        setGeneratorList(test)
        setStyleList(skyboxList)
    }

    function DisplayGeneratorList() {
        if (generatorList !== undefined) {
            return generatorList.map((generator, index) => {
                return (
                    <div key={index}>
                        <p>{generator.generator}</p>
                    </div>
                )
            })
        }
    }

    async function RequestSkybox() {
        const requestOptions = {
            headers: {'Content-Type': 'application/json'},
            method: 'POST',
            api_key: apiKey,
            body: JSON.stringify({api_key: apiKey, generator: generatorList[0].generator, prompt: prompt})
        };

        try {
            const response = await fetch("https://backend.blockadelabs.com/api/v1/imagine/requests", requestOptions);
            const data = await response.json();
            console.log(data)
            setImageRequest(data.request.id)
            return data;
        } catch (error) {
            console.error(error);
        }
    }

    async function requestSkyboxGeneration() {
        const requestOptions = {
            headers: {'Content-Type': 'application/json'},
            method: 'POST',
            api_key: apiKey,
            body: JSON.stringify({api_key: apiKey, generator: generatorList[0].generator, prompt: prompt})
        };

        try {
            const response = await fetch("https://backend.blockadelabs.com/api/v1/skybox/submit/" + style, requestOptions);
            const data = await response.json();
            console.log(data)
            setImageRequest(data.imaginations[0].id)
            return data;
        } catch (error) {
            console.error(error);
        }
    }

    async function GetImageData() {
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        }
        const response = await fetch('https://backend.blockadelabs.com/api/v1/imagine/requests/' + imageRequest + "?api_key=" + apiKey, requestOptions)
        const data = await response.json();
        setStatus(data.request.status)
        return data;
    }

    function DropdownMenu () {
        if(styleList === undefined) return null
        return <Select
            id={"ai-skybox-Style"}
            label={"Style"}
            value={style}
            onChange={(e) => {
                setStyle(e.target.value)
            }}
        >
            {styleList.map((styleItem, index) => {
                return (
                    <MenuItem key={index} value={styleItem.value.id}>{styleItem.value.name}</MenuItem>
                )
            })}
        </Select>
    }

    return (
        <div>
            <h1>AI Test Page</h1>
            <Stack direction={"column"}>
                <TextField label={"Prompt"} variant={"outlined"} onChange={(e) => {
                    setPrompt(e.target.value)
                }}/>
                <DropdownMenu/>
                <Button variant={"contained"} color={"secondary"} onClick={requestSkyboxGeneration}>Request Skybox</Button>
            </Stack>
            <DisplayGeneratorList/>
            <Typography sx={{maxWidth: "200px"}}>{status}</Typography>
            <img alt={"generated img"} width={'500px'} src={imageURL}/>
        </div>
    )
}