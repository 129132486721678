import {
    Box,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grow,
    List,
    ListItem,
    Radio,
    RadioGroup
} from "@mui/material";
import CreateProjectFormComponent from "./Form Components/CreateProjectFormComponent";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectProject, setCurrentView, setProjectNavigationIndex} from "../features/states/ProjectFunctions";
import GetUserProjects, {DeleteBulkFunction} from "../functions/ServerCallFunctions";
import Typography from "@mui/material/Typography";
import {projectSortOptions, SortProjectsByMethod} from "../functions/DataSortingFunctions";
import SortListButton from "./Structural Components/SortListButton";
import ErrorModal from "./Structural Components/ErrorModal";
import Button from "@mui/material/Button";
import {useLocation} from "react-router-dom";
import ProjectSelectionCollapsable from "./ProjectSelectionCollapsable";

function ProjectListDisplay({isProjectOwned, project: projects, setProjectErrors}) {
    const CircularProgressDisplayer = () => {
        return <Box sx={{display: 'flex', justifyContent: `center`, padding: `30px`}}>
            <CircularProgress/>
        </Box>
    }

    if (projects !== undefined && projects !== null) {
        if (projects.length === 0) {
            return <Box className={"list-card-container card-container"} width={"100%"} maxWidth={"100%"}
                        flexDirection={"column"} alignItems={"center"}>
                <Typography component="div">
                    You have no projects
                </Typography>
            </Box>
        }
    }

    if (projects !== undefined && projects !== null) {
        return (<List>
            {projects.map((project) => {
                if (project.isOwner === isProjectOwned) {
                    return (<ListItem key={project.projectId} disablePadding>
                        <ProjectSelectionCollapsable project={project} setProjectErrors={setProjectErrors}/>
                    </ListItem>)
                }
            })}
        </List>);
    } else {
        return <CircularProgressDisplayer/>;
    }
}

function DeletedProjectAlert() {

    const [showProjectDeletedAlert, setShowProjectDeletedAlert] = React.useState(false);

    const {projectDeleted, deletedProjectName} = useLocation().state || {deletedProjectName: null};

    useEffect(() => {
        if (projectDeleted) {
            const timeId = setTimeout(() => {
                setShowProjectDeletedAlert(false)
            }, 6000);

            setShowProjectDeletedAlert(true)

            return () => {
                clearTimeout(timeId)
            }
        }
    }, [projectDeleted])


    return <Grow in={showProjectDeletedAlert} unmountOnExit>
        <Box className={"list-card-container active-card-container"}>
            <Typography>Project {deletedProjectName} has been deleted.</Typography>
        </Box>
    </Grow>
}

function ProjectListDisplayContainer({setProjectErrors}) {

    const project = useSelector(selectProject);

    const [sortedProjects, setSortedProjects] = React.useState(null);
    const [sortByIndex, setSortByIndex] = React.useState(3);
    const [showingOwnedProjects, setShowingOwnedProjects] = React.useState(true);
    const [projectHeading, setProjectHeading] = React.useState("Owned Projects");

    useEffect(() => {
        if (project !== null && project !== undefined) {
            const newData = [...project];
            SortProjectsByMethod(newData, projectSortOptions[sortByIndex].value, "projectName");
            setSortedProjects(newData);
        }
    }, [project, sortByIndex])

    const handleSortOptionChange = () => {
        if (sortByIndex < 5) {
            setSortByIndex(sortByIndex + 1);
        } else {
            setSortByIndex(0);
        }
    };

    useEffect(() => {
        if (showingOwnedProjects) {
            setProjectHeading("Owned Projects");
        } else {
            setProjectHeading("Joined Projects");
        }
    }, [showingOwnedProjects])

    function HandleDisplayedProjects() {
        setShowingOwnedProjects(!showingOwnedProjects);
    }

    return <>
        <SortListButton
            arrayToSort={projectSortOptions}
            callback={handleSortOptionChange}
            sortByIndex={sortByIndex}
        />
        <Box width={"100%"} sx={{display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: `2px`}}>
            <Button
                sx={{backgroundColor: "#4f4f4f", width: "150px"}} className="sort-list-button"
                onClick={HandleDisplayedProjects} variant={"contained"}>
                {projectHeading}
            </Button>
        </Box>
        <CreateProjectFormComponent/>
        <DeletedProjectAlert/>
        <Typography fontSize={"16px"} variant="h6" component="h2" textTransform={"uppercase"} marginY={1}>
            {projectHeading}
        </Typography>
        <ProjectListDisplay isProjectOwned={showingOwnedProjects} project={sortedProjects}
                            setProjectErrors={setProjectErrors}/>
        <DevDeleteProjects projects={project}/>
    </>
}

function DevDeleteProjects({projects}) {
    if (process.env.REACT_APP_STATE === 'development') {
        return <Button variant={"contained"} color={"secondary"} onClick={() => DeleteBulkFunction(projects)}>DEV:
            Delete Projects</Button>
    }
}

function ProjectsInformationPage() {
    const dispatch = useDispatch();
    const [errorState, setErrorState] = React.useState({open: false, title: "", message: ""});

    useEffect(() => {
        GetUserProjects();

        dispatch(setCurrentView(""));
        dispatch(setProjectNavigationIndex(0))
        window.scrollTo(0, 0)

        if (window.vuplex) {
            const params = {
                type: 'projectInfo', value: null,
            }
            window.vuplex.postMessage(params)
        }
    }, []);

    function setProjectErrors() {
        setErrorState({
            open: true, title: "Error", content: "You must be a Pro user to join a Pro project",
        })
    }

    function closeErrorModal() {
        setErrorState({
            open: false, title: "", content: "",
        })
    }

    return <Box className={"full-width-blocker"}>
        <Box className={"inner-box"}>
            <Box className="Project Buttons">
                <ProjectListDisplayContainer setProjectErrors={setProjectErrors} closeErrorModal={closeErrorModal}/>
                <ErrorModal settings={errorState} closeCallback={closeErrorModal}/>
            </Box>
        </Box>
    </Box>
}

export default ProjectsInformationPage;