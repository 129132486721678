import React, {useEffect, useState} from 'react';
import {Box, CircularProgress, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import AudioQualityToggle from "../components/AccountSettingsComponents/AudioQualityToggle";
import Posts from "../api-calls/Posts";
import Typography from "@mui/material/Typography";
import {DeleteProject, GetProjectInfoFromServer, LeaveProject} from "../functions/ServerCallFunctions";
import {useNavigate} from "react-router-dom";

function ProFeatures(props) {
    const project = props.project;
    let audioFormat = project.audioFormat;

    function updateAudioQualityOnServer(quality) {
        let audioFormat = quality ? 2 : 1;
        let data = {
            projectId: project.projectId,
            audioFormat: audioFormat
        }
        Posts(data, `Projects/update-audio-format`)
    }

    return (
        <Stack className={"Pro Features"} display={"flex"} direction={"row"} alignItems={"center"} justifyContent={"flex-end"} spacing={1} >
            <Typography component="div"> Audio Quality: </Typography>
            <AudioQualityToggle audioQuality={audioFormat}
                                callback={updateAudioQualityOnServer}/>
        </Stack>
    )
}

function ProjectSettingsPage({projectId}) {

    const [project, setProject] = useState();
    const [projectDeletePending, setProjectDeletePending] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        GetProjectInfo(projectId);
    }, [projectId])

    async function GetProjectInfo(projectId){
        let project = await GetProjectInfoFromServer(projectId, false);
        setProject(project);
    }

    if(project === undefined || projectDeletePending) {
        return <CircularProgress/>
    }

    function DeleteProjectCallback() {
        setProjectDeletePending(true);
        DeleteProject(project.projectId, project.projectName).then(r => {
            if (r.code === 200) {
                navigate("/projects", {state: {projectDeleted: true, deletedProjectName: project.projectName}});
            } else {
                setProjectDeletePending(false);
            }
        })

    }

    return <Box id="box">
        {/*project.isAdmin ? <RenameProjectPanel/> : null */}
        {/*<CloudStorageManagement/> */}
        <ProFeatures project={project}/>
        {/*
        <div>
            Project Server
            <Tooltip title="Oh hey there, I didn't expect to see you">
                <HelpOutlineIcon sx={{color: blue[300]}}/>
            </Tooltip>
        </div>
        <div>
           <RegionSelection register={register} defaultRegion={props.region}/>
        </div>
        */}
    </Box>
}

export default ProjectSettingsPage;