import {
    Box,
    Card, CardActionArea,
    CardContent,
    CardMedia,
    FormControlLabel,
    Radio, Stack,
    styled
} from "@mui/material";
import React, {useEffect} from "react";
import GetsDirect from "../api-calls/GetsDirect";

export const TemplateRadioButton = styled((props) => <Radio {...props} />)(() => ({
    '& .MuiSvgIcon-root': {
        fontSize: `1rem`,
        padding: `0 2px 0 0`,
    },
    '&.MuiIconButton-label': {
        padding: `0px`,
        fontSize: `0.8rem`,
        textWrap: `balance`,
    },
    '&.MuiButtonBase-root': {
        padding: `0px`,
    },
    marginX: `0px`,
    whiteSpace: `nowrap`,
}));


export function TemplateSelectionImageButtonGroup(props) {
    return ImageButtonGroup(`100`, '100', props.register, props.control, props.setValue)
}

function ImageButtonGroup(width, height, register, control, setValue) {

    const [templates, setTemplates] = React.useState();

    async function GetTemplates() {
        let data = await GetsDirect("ProjectTemplate/get-project-templates")

        let filteredData = data.data.filter((template) => {
            return template.templateName !== "Getting Started" && template.templateName !== "Blank"
        }).sort((a, b) => {
            if (a.templateName === "Light World") return -1;
            if (b.templateName === "Light World") return 1;
            if (a.templateName === "Dark World") return -1;
            if (b.templateName === "Dark World") return 1;
            return 0;
        });

        //sort filtered data so Lightworld and Dark world are the first two elements


        setTemplates(filteredData)
    }

    const [activeButtonOption, setActiveButtonOption] = React.useState("Blank");

    useEffect(() => {
        GetTemplates();
    }, [])

    function handleButtonValue(value) {
        setValue("templateName", value)
        setActiveButtonOption(value);
    }

    function ButtonCard({template, index}) {
        return <Box
            key={index}
            sx={{
                padding: `2px`,
                flexDirection: `column`,
                width: width + `px`,
                maxHeight: `120px`,
                height: `120px`,
                '&:hover': {
                    backgroundColor: `#e0e0e042`,
                },
                '&.Mui-selected': {
                    backgroundColor: `#709fff69 !important`,
                }
            }}
        >
            <Card elevation={6}
                  onClick={() => {
                      handleButtonValue(template.templateName)
                  }}
                  className={"template-button"}
                  sx={{
                      width: `100px`,
                      height: `120px`,
                      display: `flex`,
                      flexDirection: `column`,
                      justifyContent: `space-between`,
                      alignItems: `center`,
                      backgroundColor: '#292929'
                  }}>
                <CardActionArea sx={{height:"120px"}}>
                    <CardMedia
                        component="img"
                        width={width}
                        sx={{objectFit: `cover`, width: width + `px`, height: 80 + `px`}}
                        className={"template-button-picture"}
                        image={template.thumbNailPath}
                        alt={template.templateName}
                    />
                    <CardContent
                        sx={{
                            padding: `6px 6px 6px 6px`,
                            display: `flex`,
                            height: `40px`,

                        }}
                    >
                        <FormControlLabel
                            sx={{
                                whiteSpace: `nowrap`,
                                fontSize: `0.8rem`,
                                '& .MuiTypography-root': {
                                    fontSize: `14px`,
                                    textWrap: `balance`,
                                    whiteSpace: `pre-wrap`,
                                    textAlign: `center`,
                                    lineHeight: 1
                                },
                                marginLeft: `0px`,
                                marginRight: `0px`
                            }}
                            className={"template-button-controller"}

                            control={<TemplateRadioButton/>}
                            checked={activeButtonOption === template.templateName}
                            label={template.templateName}
                            value={template.templateName}/>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Box>
    }

    if (templates !== undefined) {
        return <Stack>
            <Box sx={{flexDirection: `row`, flexWrap:`wrap`, display: `flex`, justifyContent: `space-between`}}>
                {templates.map((template, index) => (
                    <ButtonCard template={template} index={index} key={index}/>
                ))}
            </Box>
            <Box sx={{display: `flex`, justifyContent: `center`, my: 2}}>
                <Card
                    sx={{
                    width: "100%", backgroundColor: '#292929'

                    }}
                    onClick={() => {
                        handleButtonValue("Blank")
                    }}
                >
                    <CardActionArea>
                        <CardContent
                            sx={{
                                display: `flex`,
                                flexDirection: `column`,
                                justifyContent: `center`,
                                alignItems: `center`
                            }}
                        >
                            <FormControlLabel
                                sx={{
                                    whiteSpace: `nowrap`,
                                    fontSize: `0.8rem`,
                                    '& .MuiTypography-root': {
                                    },
                                }}
                                control={<TemplateRadioButton/>}
                                checked={activeButtonOption === "Blank"}
                                label={"Blank Project"}
                                value={"Blank"}
                                onClick={() => {
                                    handleButtonValue("Blank")
                                }}
                            />
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Box>
        </Stack>
    }
}