import {ReactComponent as Performer} from "./TeamMemberIcons/Performer.svg";
import {ReactComponent as Director} from "./TeamMemberIcons/Director.svg";
import {ReactComponent as Lighting} from "./TeamMemberIcons/Lighting.svg";
import {ReactComponent as Camera} from "./TeamMemberIcons/Camera.svg";
import {ReactComponent as Watcher} from "./TeamMemberIcons/Watcher.svg";
import {ReactComponent as Other} from "./TeamMemberIcons/Stars.svg";

export const TeamMemberRoles =
    [
        {value: "Director", icon: <Director/>},
        {value: "Producer", icon: <Watcher/>},
        {value: "Actor", icon: <Performer/>},
        {value: "Camera Op...", icon: <Camera/>},
        {value: "Lighting A...", icon: <Lighting/>},
        {value: "All Star", icon: <Other/>},
    ];

