import {
    Box,
    CircularProgress,
} from "@mui/material";
import React from "react";
import HubspotForm from 'react-hubspot-form'
import "./styles/Survey.scss"
import {useSelector} from "react-redux";
import $ from "jquery";

export default function Survey() {

    const emailAddress = useSelector(state => state.loginToken.emailAddress)

    return (<Box sx={{marginTop: "30px"}} component={"form"}>
        <Box className={"centre-box"}>
            <Box className={"inner-box"} sx={{maxWidth:"600px"}}>
                <HubspotForm
                    portalId='8788699'
                    formId='5c25d7e1-0e29-4dd0-84b4-c0a20eb15fd2'
                    cssClass={"survey-form"}
                    loading={<CircularProgress/>}
                    redirectUrl={"/"}
                    onFormReady={(form) => {
                        $('input[name="email"]').val(emailAddress).change()
                    }
                }
                />
            </Box>
        </Box>
    </Box>)
}