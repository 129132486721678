import {Auth} from "aws-amplify";
import {setLoginState, setTokens} from "../features/states/ProjectFunctions";
import {store} from "../app/store";
import {SendNullToken} from "../components/Structural Components/UnityCommandsContext";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

export async function getTokenValueFromAWS() {
    try {
        const promise = await Auth.currentSession();
        const accessToken = await promise.getAccessToken();
        const accessTokenValue = accessToken.getJwtToken();

        let idToken = await promise.getIdToken();
        let idTokenValue = idToken.getJwtToken();

        let refreshToken = await promise.getRefreshToken();
        let refreshValue = refreshToken.getToken();

        const tokens = {
            accessToken: accessTokenValue,
            idToken: idTokenValue,
            refreshToken: refreshValue
        }

        store.dispatch(setTokens(tokens));

        return accessTokenValue;
    } catch (e) {
    }
}

export async function getNewTokenValueFromAWS() {
    const state = store.getState();
    const promise = await Auth.currentAuthenticatedUser();
    let token = new AmazonCognitoIdentity.CognitoRefreshToken({RefreshToken: state.loginToken.userTokens.refreshToken});
    await promise.refreshSession(token, (err, session) => {
        if (err) {
            console.log(err);
        }

        const tokens = {
            accessToken: session.accessToken.jwtToken,
            idToken: session.idToken.jwtToken,
            refreshToken: session.refreshToken.token
        }
        store.dispatch(setTokens(tokens));

        return session.accessToken.jwtToken;
    })
}

export async function SignOutOfClient(){
    try {
        await Auth.signOut();
        SendNullToken();
        store.dispatch(setLoginState(false));
    } catch (error) {
        console.log('error signing out: ', error);
    }
}