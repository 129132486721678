import {
    setApplicationVersionInfo
} from "../../features/states/ProjectFunctions";
import {Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {DownloadForOffline} from "@mui/icons-material";
import {UnityLink} from "../Structural Components/UnityCommandsContext";
import {store} from "../../app/store";

export async function checkApplicationVersion(){
    if(!window.vuplex) return true;

    await new Promise(r => setTimeout(r, 2000));

    let currentVersion = store.getState().OpenInUnity.applicationVersion;


    let versionInfo = {
        versionNumber: null,
        identifier: null,
        downloadLink: "http://www.animotive.com"
    }

    if(currentVersion === null) return false;

    let versionNumber = splitTextFromVersionNumber(currentVersion)
    let isLatest = parseLatestVersion(versionNumber);
    store.dispatch(setApplicationVersionInfo(versionInfo))
    return isLatest

    function splitTextFromVersionNumber(string){

        if(typeof string !== "string"){
            versionInfo.versionNumber = string;
            return versionInfo;
        }

        let splitString = string.split(" ");
        versionInfo.versionNumber = splitString[0];
        if(splitString.length === 1){
            return versionInfo;
        }
        versionInfo.identifier = splitString[1];
        return versionInfo;
    }

    function parseLatestVersion(versionInfo){
        if(versionInfo.identifier === null){
            return versionInfo.versionNumber >= process.env.REACT_APP_LATEST_VERSION;
        }
        //change text to lowercase
        versionInfo.identifier = versionInfo.identifier.toLowerCase();

        switch (versionInfo.identifier){
            case "beta":
                versionInfo.downloadLink = "https://app.animotive.com/#/download";

                return versionInfo.versionNumber >= process.env.REACT_APP_LATEST_BETA_VERSION;
            case "moonbug":
                return versionInfo.versionNumber >= process.env.REACT_APP_LATEST_MOONBUG_VERSION;
            default:
                return false;
        }
    }
}

export function AnimotiveDownloadLatestButton({downloadLink}){
    return (
        <Stack direction={"row"} height={"100%"} alignItems={"center"} bgcolor={"#4a89b7"}
               padding={1} spacing={1} sx={{cursor:"pointer"}}
               borderRadius={2}
               onClick={()=>UnityLink(downloadLink)}>
            <DownloadForOffline color={"primary"}/>
            <Typography whiteSpace={"nowrap"} component="div" sx={{display: 'inline-flex'}}
                        textTransform={"uppercase"}>Update available</Typography>
        </Stack>
    )
}