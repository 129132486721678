import {Box, Button, Stack} from "@mui/material";
import {CheckboxField} from "@aws-amplify/ui-react";
import React from "react";
import InformationModal from "./InformationModal";
import {PrivacyPolicyText, TermsAndConditionsText} from "../TermsAndConditionsText";

export function TermsAndConditionsButton () {

    const TermsConditions =
        <div>I agree to Animotive Terms & Conditions</div>;

    return (
        <Stack direction={"column"} alignItems={"flex-start"}>
            <CheckboxField
                name={'TermsServices'}
                required={true}
                label={TermsConditions}
            />
            <ClickableButton buttonText={"Read T&C’s"} title={"Terms & Conditions"} content={TermsAndConditionsText}/>
        </Stack>
    )
}

export function PrivacyPolicyButton() {

        const PrivacyPolicy =
            <div>I agree to Animotive Privacy Policy</div>;

        return (
            <Stack direction={"column"} alignItems={"flex-start"}>
                <CheckboxField
                    name={'PrivacyPolicy'}
                    required={true}
                    label={PrivacyPolicy}
                />
                <ClickableButton buttonText={"Read Privacy Policy"} title={"Privacy Policy"} content={PrivacyPolicyText}/>
            </Stack>
        )
}

function ClickableButton({buttonText, content, title}) {

    const [openDialogue, setOpenDialogue] = React.useState(false);

    function OpenDialogue() {
        setOpenDialogue(true);
    }

    function CloseDialogue() {
        setOpenDialogue(false);
    }

    return (<Box>
            <Button variant="text" color={"info"} onClick={OpenDialogue}>
                {buttonText}
            </Button>
            <InformationModal
                open={openDialogue}
                handleClose={CloseDialogue}
                title={title}
                informationText={content}
            />
        </Box>
    )
}