import {GetScenesFromServer} from "./ServerCallFunctions";

export function getCCULimit() {
        return process.env.REACT_APP_PRO_CCU_LIMIT;
}

export function getMaxTeamMembers() {
    return process.env.REACT_APP_PRO_MAX_TEAM_MEMBERS;
}

export async function countActiveUsersInProject(projectID, limit) {
    let count = 0;

    let response = await GetScenesFromServer(projectID)
    response.map((scene) => {
        if (scene.userIdsInTheScene.length > 0) {
            count += scene.userIdsInTheScene.length;
        }
    })

    return count < limit;
}