import {useEffect, useState} from "react";
import {Box, Button, Card, CardActionArea, CircularProgress, Tab, Tabs} from "@mui/material";
import {useSearchParams} from "react-router-dom";
import {
    selectEmailAddress,
    selectLoginToken,
} from "../features/states/ProjectFunctions";
import {useSelector} from "react-redux";
import {GetUserInfoFromServer} from "../functions/ServerCallFunctions";
import Typography from "@mui/material/Typography";
import {UnityLink} from "../components/Structural Components/UnityCommandsContext";
import {parseInt} from "lodash";

function ProductSelection(props) {
    const [Paddle, setPaddle] = useState()

    const emailAddress = useSelector(selectEmailAddress);

    useEffect(() => {
        setPaddle(window.Paddle)
    }, []);

    const productIDsStruct = {
        Title: "Indie",
        Monthly: process.env.REACT_APP_PADDLE_INDIE_MONTHLY_PLAN_ID,
        Yearly: process.env.REACT_APP_PADDLE_INDIE_ANNUAL_PLAN_ID,
        Tier: 1,
    }

    const [productIDs, setProductIDs] = useState(productIDsStruct)

    const [monthlyPrice, setMonthlyPrice] = useState()
    const [yearlyPrice, setYearlyPrice] = useState()

    const [pricingTier, setPricingTier] = useState(0)

    const handleChange = (event, newValue) => {
        setPricingTier(newValue);
    };

    useEffect(()=> {
        if(Paddle === undefined){
            return;
        }
        switch (pricingTier){
            case 0:
                setProductIDs({
                    Title: "Indie",
                    Monthly: process.env.REACT_APP_PADDLE_INDIE_MONTHLY_PLAN_ID,
                    Yearly: process.env.REACT_APP_PADDLE_INDIE_ANNUAL_PLAN_ID,
                    Tier: 1,
                })
                break;
            case 1:
                setProductIDs({
                    Title: "Pro",
                    Monthly: process.env.REACT_APP_PADDLE_PRO_MONTHLY_PLAN_ID,
                    Yearly: process.env.REACT_APP_PADDLE_PRO_ANNUAL_PLAN_ID,
                    Tier: 2,
                })
                break;
        }

    },[pricingTier, Paddle])

    useEffect(() => {
        if(Paddle === undefined){
            return;
        }
        Paddle.Product.Prices(productIDs.Monthly, function (prices) {
            let vat;
            let pricing;

            if (prices.country === "GB") {
                vat = " inc. VAT";
                pricing = prices.recurring.price.gross;
            } else {
                vat = "";
                pricing = prices.recurring.price.net;
            }

            const monthlyPrice = {
                displayValue: pricing + "/m" + vat,
                productName: `Animotive ${productIDs.Title} (Monthly)`,
                productID: productIDs.Monthly,
                productPrice: prices.recurring.price.gross,
                productTaxFree: prices.recurring.price.net,
                productTax: prices.recurring.price.tax,
            }

            setMonthlyPrice(monthlyPrice)
        })

        Paddle.Product.Prices(productIDs.Yearly, function (prices) {
            let vat;
            let pricing;

            if (prices.country === "GB") {
                vat = " inc. VAT";
                pricing = prices.recurring.price.gross;
            } else {
                vat = ""
                pricing = prices.recurring.price.net;
            }

            const annualPrice = {
                displayValue: getMonthlyAmount(pricing)  + "/m" + vat,
                productName: `Animotive ${productIDs.Title} (Annual)`,
                productID: productIDs.Yearly,
                productPrice: prices.recurring.price.gross,
                productTaxFree: prices.recurring.price.net,
                productTax: prices.recurring.price.tax,
            }

            setYearlyPrice(annualPrice)
        })
    }, [productIDs])

    function getMonthlyAmount(price) {
        var currencySymbol = price.match(/^\D+/)[0];
        var numericValue = parseFloat(price.replace(/[^\d.-]/g, '')).toFixed(2);
        var monthly = numericValue / 12
        return currencySymbol + monthly.toFixed(2)
    }

    function loadCheckout(productID,Tier) {

        Paddle.Checkout.open({
            method: 'overlay',
            product: productID,
            email: emailAddress,
            quantity: 1,
            allowQuantity: false,
            passthrough: `{"userTier":"${Tier}"}`,
            disableLogout: true,
            successCallback: function (data) {
                console.log(data)
                props.checkoutCallback(true)
            },
        })
    }

    function ButtonCard(props) {

        return <Box
            sx={{
                width: `100%`,
                '&.Mui-selected': {
                    backgroundColor: `#709fff69 !important`,
                }
            }}>
            <Card
                elevation={6}
                onClick={props.callback}
            >
                <CardActionArea>
                    <Box
                        sx={{
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "space-between",
                            padding: `20px`,
                        }}
                    >
                        {props.children}
                    </Box>
                </CardActionArea>
            </Card>
        </Box>
    }

    function PricingOptions(){
        return <Box display={`flex`}
                    className={"list-card-container"}
                    sx={{
                        alignItems: 'center',
                        justifyContent: `center`,
                        flexDirection: "column"
                    }}>
            <Box>
                Please select your preferred billing plan
            </Box>
            <Button variant={"outlined"} color={"info"} onClick={() => UnityLink("https://www.animotive.com/pricing")}>See Pro
                Features</Button>
            <Tabs value={pricingTier} onChange={handleChange}>
                <Tab value={0} label="Indie"/>
                <Tab value={1} label="Pro"/>
            </Tabs>
            <ButtonCard productID={productIDs.Yearly} value={yearlyPrice} callback = {() => {loadCheckout(productIDs.Yearly,productIDs.Tier)}}>
                <Typography variant={"p"}>Annual</Typography>
                <Typography variant={"p"}>{yearlyPrice.displayValue}</Typography>
            </ButtonCard>
            <ButtonCard productID={productIDs.Monthly} value={monthlyPrice} callback = {() => {loadCheckout(productIDs.Monthly,productIDs.Tier)}}>
                <Typography variant={"p"}>Monthly</Typography>
                <Typography variant={"p"}>{monthlyPrice.displayValue}</Typography>
            </ButtonCard>
        </Box>;
    }

    if(yearlyPrice === undefined || monthlyPrice === undefined)
    {
        return <CircularProgress/>
    }
    else{
        return <PricingOptions/>
    }
}

export default function CheckoutPaddle() {
    const userInfo = useSelector(selectLoginToken)
    console.log(userInfo)

    const [queryParams] = useSearchParams()
    const [checkoutComplete, setCheckoutComplete] = useState(false)

    function CheckoutCompleted(value){
        setCheckoutComplete(value)
    }

    useEffect(() => {
        if(process.env.REACT_APP_STATE === "development") {
            window.Paddle.Environment.set('sandbox')
        }
        let vendorID = parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID)

        window.Paddle.Setup({
            vendor: vendorID,
        })
    },[])

    useEffect(() => {
            if (checkoutComplete) {
                GetUserInfoFromServer()
            } else {
                setCheckoutComplete(queryParams.get("success") || false)
            }
        }, [checkoutComplete]
    )

    function MainCheckout() {
        if (checkoutComplete) {
            return <Box display={`flex`} flexGrow={1}
                        sx={{
                            minWidth: `150px`,
                            maxWidth: `400px`,
                            alignItems: 'center',
                            justifyContent: `center`,
                            flexDirection: "column"
                        }}>
                <Box className={"list-card-container"} flexDirection={"column"}>
                    Thanks for upgrading to Pro!
                    <Button variant={"contained"} color={"secondary"} href={"/"}>Go back to home</Button>
                </Box>
            </Box>;
        }

        if (userInfo.isPro) {
            return <Box display={`flex`} flexGrow={1}
                        sx={{
                            minWidth: `150px`,
                            maxWidth: `400px`,
                            alignItems: 'center',
                            justifyContent: `flex-start`,
                            flexDirection: "column"
                        }}>
                <Box className={"list-card-container"} flexDirection={"column"}>
                    You are already a Pro user!
                    <Button variant={"contained"} color={"secondary"} href={"/"}>Go back to home</Button>
                </Box>
            </Box>;
        }

        if (window.Paddle) {
            return <Box display={`flex`} flexGrow={1}
                        sx={{
                            minWidth: `150px`,
                            maxWidth: `400px`,
                            alignItems: 'center',
                            justifyContent: `flex-start`,
                            flexDirection: "column"
                        }}>
                <ProductSelection checkoutCallback={CheckoutCompleted}/>
            </Box>;
        }

    }

    return <Box sx={{maxWidth: "700px", justifyContent: "center", display: "flex"}} className={"centre-box"}>
        <MainCheckout/>
    </Box>
}