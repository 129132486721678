import {Box} from "@mui/material";
import React from "react";
import GettingStarted from "./ContentPages/GettingsStarted";

export default function LandingPageV2() {

    return (
        <Box display={"flex"} flexDirection={"column"}>
            <Box width={"100%"} overflow={"auto"}>
            </Box>
            <Box sx={{maxWidth: {xs: "90vw", sm: "100%", md: "60vw"}}}>
                <GettingStarted/>
            </Box>
        </Box>

    )
}