import {Box, Button} from "@mui/material";
import Typography from "@mui/material/Typography";
import {projectSortOptions} from "../../functions/DataSortingFunctions";
import React from "react";

export default function SortListButton(props){
    return (
        <Box sx={{display:"flex", alignItems:"center", justifyContent:"flex-end", marginY:.5}} className="sort-list-button" flexDirection={"row"}>
            <Typography marginRight={1}>Filter by:</Typography>
            <Button variant={"contained"} sx={{backgroundColor:"#4f4f4f", width:"150px"}} onClick={props.callback}>{projectSortOptions[props.sortByIndex].displayValue}</Button>
        </Box>
    )
}