import {AccountCircleOutlined} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import React from "react";
import Button from "@mui/material/Button";
import {useSelector} from "react-redux";
import {selectLoginState} from "../features/states/ProjectFunctions";
import {Link as RouterLink} from "react-router-dom";

export default function MyAccountButton(props) {
    const loggedIn = useSelector(selectLoginState)
    if(!loggedIn) return (<></>)
    else
    {
        return (<Button sx={{width:{xs:"100%", md:"fit-content"}, justifyContent:{xs:"flex-start"}}} component={RouterLink} to={"/settings"}>
                <AccountCircleOutlined size={"large"}/>
                <Typography marginX={1} whiteSpace={"nowrap"} component="div" sx={{display: 'inline-flex'}}
                            textTransform={"uppercase"}>
                    My Account
                </Typography>
            </Button>
        )
    }

}
