import {createSlice} from "@reduxjs/toolkit";

export const projectSlice = createSlice({
    name: "projectList",
    initialState: {
        value: null,
    },
    reducers: {
        setProject: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const loginSlice = createSlice({
    name: "token",
    initialState: {
        userTokens: {
            accessToken: null,
            refreshToken: null,
            idToken: null,
        },
        userName: null,
        emailAddress: null,
        loginState: false,
        userId: null,
        isPro: false,
        userTier: null,
        storageUsedInBytes: 0,
        hasDataCollectionConsent: null,
        displayId: null,
        allocatedStorageGBs: 0,
    },
    reducers: {
        setTokens: (state, action) => {
            state.userTokens = action.payload;
        },
        setUsername: (state, action) => {
            state.userName = action.payload;
        },
        setEmailAddress: (state, action) => {
            state.emailAddress = action.payload;
        },
        setLoginState: (state, action) => {
            state.loginState = action.payload;
        },
        setUserInfo: (state, action) => {
            state.userId = action.payload.id;
            state.displayId = action.payload.uid;
            state.isPro = action.payload.isPro;
            state.hasDataCollectionConsent = action.payload.hasDataCollectionConsent;
            state.userTier = action.payload.userTier;
            state.storageUsedInBytes = action.payload.storageUsedInBytes;
            state.allocatedStorageGBs = action.payload.allocatedStorageGbs;
        },
    },
});

export const projectInfoSlice = createSlice({
    name: "projectInfo",
    initialState: {
        ID: null,
        teamMembers: null,
        scenes: null,
        currentProject: null,
    },
    reducers: {
        setProjectId: (state, action) => {
            state.ID = action.payload;
        },
        setTeamMembers: (state, action) => {
            state.teamMembers = action.payload;
        },
        setScenes: (state, action) => {
            state.scenes = action.payload;
        },
        setCurrentProject: (state, action) => {
            state.currentProject = action.payload;
        },
    },
});

export const unitySlice = createSlice({
    name: "unity",
    initialState: {
        value: false,
        applicationVersion: null,
        isLatestVersion: true,
        versionInfo: {
            version: null,
            releaseNotes: null,
            downloadLink: null,
            identifier: null
        }
    },
    reducers: {
        setUnity: (state, action) => {
            state.value = action.payload;
        },
        setApplicationVersion: (state, action) => {
            state.applicationVersion = action.payload;
        },
        setIsLatestVersion: (state, action) => {
            state.isLatestVersion = action.payload;
        },
        setApplicationVersionInfo: (state, action) => {
            state.versionInfo = action.payload;
        }
    },
});

export const invitesSlice = createSlice({
    name: "invites",
    initialState: {
        value: null,
        count: 0,
    },
    reducers: {
        setInvites: (state, action) => {
            state.value = action.payload;
            state.count = action.payload.length;
        },
    },
});

export const navigationSlice = createSlice({
    name: "navigation",
    initialState: {
        currentView: "",
        primaryNavIndex: 0,
        projectNavIndex: 0,
        redirectionPage: null
    },
    reducers: {
        setPrimaryNavigationIndex: (state, action) => {
            state.primaryNavIndex = action.payload;
        },
        setProjectNavigationIndex: (state, action) => {
            state.projectNavIndex = action.payload;
        },
        setCurrentView: (state, action) => {
            state.currentView = action.payload;
        },
        setRedirectionPage: (state, action) => {
            state.redirectionPage = action.payload;
        }
    },
});

export const {setProject} = projectSlice.actions;
export const {setTokens, setUsername, setEmailAddress, setLoginState, setUserInfo} = loginSlice.actions;
export const {setUnity, setApplicationVersion, setIsLatestVersion,setApplicationVersionInfo} = unitySlice.actions;
export const {setProjectId, setTeamMembers, setScenes, setCurrentProject} = projectInfoSlice.actions;
export const {setInvites} = invitesSlice.actions;
export const {setPrimaryNavigationIndex, setProjectNavigationIndex, setCurrentView, setRedirectionPage} = navigationSlice.actions;

export const selectProject = (state) => state.projectList.value;

export const selectTeamMembers = (state) => state.projectInformation.teamMembers;
export const selectScenes = (state) => state.projectInformation.scenes;
export const selectCurrentProject = (state) => state.projectInformation.currentProject;

export const selectTokens = (state) => state.loginToken.userTokens;
export const selectAccessToken = (state) => state.loginToken.accessToken;
export const selectRefreshToken = (state) => state.loginToken.refreshToken;
export const selectJWTToken = (state) => state.loginToken.token;
export const selectUsername = (state) => state.loginToken.userName;
export const selectEmailAddress = (state) => state.loginToken.emailAddress;
export const selectLoginState = (state) => state.loginToken.loginState;
export const selectUserId = (state) => state.loginToken.userId;
export const selectUserStorageLimit = (state) => state.loginToken.allocatedStorageGBs;

export const selectLoginToken = (state) => state.loginToken;

export const selectUnity = (state) => state.OpenInUnity.value;
export const selectApplicationVersion = (state) => state.OpenInUnity.applicationVersion;
export const selectIsLatestVersion = (state) => state.OpenInUnity.isLatestVersion;
export const selectApplicationVersionInfo = (state) => state.OpenInUnity.versionInfo;

export const selectInvites = (state) => state.invitesList.value;

export const selectProjectNavigationIndex = (state) => state.navigation.projectNavIndex;
export const selectCurrentView = (state) => state.navigation.currentView;
export const selectRedirectionPage = (state) => state.navigation.redirectionPage;

export default projectSlice.reducer;
