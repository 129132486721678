import React from "react";
import {
    Box, Button, FormControlLabel, TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as Yup from 'yup';
import {DropdownSelectionWithIcons} from "../TeamUIComponents/DropdownSelection";
import {TeamMemberRoles} from "../../InformationReferences/TeamMemberRoles";
import {CheckboxSelection} from "../TeamUIComponents/CheckboxSelection";
import Posts from "../../api-calls/Posts";
import CreationCollapsable from "../Structural Components/CreationCollapsable";
import {GetTeamInfoFromServer} from "../../functions/ServerCallFunctions";
import ErrorModal from "../Structural Components/ErrorModal";
import {store} from "../../app/store";
const state = store.getState();


const emailOrUsernameId = Yup.string().test(
    'emailOrUsernameId',
    'Must be an email address or a valid userID with the format "username#idnumber"',
    async (value) => {
        if (value.includes('@')) {
            return Yup.string().email().isValidSync(value);
        } else {
            return Yup.string().matches(/^.*#.*$/, 'Must be a valid userID').isValidSync(value);
        }
    }
).test(
    'emailOrUsernameId',
    'Cannot be your own email or userID',
    async (value) => {
        if (value.includes('@')) {
            return value !== state.loginToken.emailAddress;
        } else {
            return value !== state.loginToken.displayId;
        }
    }
);

const ValidationSchema = Yup.object().shape({
    emailOrUsernameId,
});

export default function AddTeamMemberFormComponent(props) {

    const [errorModalSettings, setErrorModalSettings] = React.useState({
        open: false,
        title: "",
        message: ""
    });

    function handleErrorClose() {
        setErrorModalSettings({open: false, title: "", content: ""});
    }

    async function handleAddTeamMember(data) {

        if(data.role === "0"){
            data.role = 0
        }

        let teamInvite = {
            projectId: props.projectId,
            userIdentifier: data.emailOrUsernameId,
            userRole: data.role,
            isAdmin: data.isAdmin
        }

        let response = await Posts(teamInvite, "Projects/add-users");

        if (response.code === 200) {
            GetTeamInfoFromServer(props.projectId);
        } else {
            setErrorModalSettings({
                open: true,
                title: "Error",
                content: "Problem Sending invite, please ensure User ID or Email is correct"
            })
        }
    }

    const resetValues = {
        emailAddress: ''
    }

    const CollapsableContent = ({register, errors}) => {
        return <>
            <Box
                sx={{display: 'flex', flexGrow: 1, flexDirection: 'column', alignItems: 'left'}}
            >
                <TextField
                    id="emailOrUsernameId"
                    hiddenLabel={true}
                    label={"Email Address Or Unique ID"}
                    name="emailOrUsernameId"
                    {...register("emailOrUsernameId", {required: "Email Address or Unique ID is required"})}
                    size={"small"}
                    sx={{marginY: 1, flexGrow: 1}}
                    error={!!errors.projectTitle}
                />
                <Typography variant={"caption"} color={"error"}>{errors.emailOrUsernameId?.message}</Typography>
            </Box>
            <Box sx={{flexDirection: `row`}}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <DropdownSelectionWithIcons options={TeamMemberRoles} name={"role"} label={"Role"}
                                                register={register}
                                                defaultValue={0}/>
                    <FormControlLabel
                        sx={{
                            maxWidth: 200,
                        }}
                        control={<CheckboxSelection
                            name={`isAdmin`}
                            defaultValue={false}
                            label="Admin"/>}
                        label="Administrator"
                        {...register("isAdmin")}
                    />
                </Box>
            </Box>
            <Box justifyContent={'flex-end'} display={`flex`}>
                <Button
                    type={"submit"}
                    sx={{maxWidth: 200, alignSelf: "flex-end"}}
                    variant={"contained"}
                    color={"secondary"}
                >Invite</Button>
            </Box>
        </>
    }



    return <Box>
        <CreationCollapsable
        title= {props.disabled ? "Team Limit has been reached" : "Add a new Team Member"}
        callback={handleAddTeamMember}
        ValidationSchema={ValidationSchema}
        resetValues={resetValues}
        disabled={props.disabled}
    >
        <CollapsableContent/>
    </CreationCollapsable>
        <ErrorModal settings={errorModalSettings} closeCallback={handleErrorClose}/>
    </Box>

}