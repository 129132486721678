import {Authenticator, Radio, RadioGroupField, TextField, useAuthenticator} from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress, Stack} from "@mui/material";
import './styles/AmplifyAuthentication.scss'
import Typography from "@mui/material/Typography";
import {Link as RouterLink, useNavigate, useSearchParams} from "react-router-dom";
import {Auth} from "aws-amplify";
import {
    TermsAndConditionsButton, PrivacyPolicyButton
} from "../components/Structural Components/Registration Components";
import {checkIfStringIsAlphaNumeric, updateBackgroundColour} from "../functions/UniversalJavascriptFunctions";
import {useDispatch} from "react-redux";
import {setCurrentView, setRedirectionPage} from "../features/states/ProjectFunctions";
import {getLoginInformation} from "../App";

const formFields = {
    signIn: {
        username: {
            label: 'Email', placeholder: 'Enter your email', required: true, type: 'email',
        }, password: {
            label: 'Password', placeholder: 'Enter your password', required: true, type: 'password',
        },
    }, signUp: {
        password: {
            hideShowPassword: true,
        }, confirm_password: {
            hideShowPassword: true,
        },
    }
}

const services = {
    async handleConfirmSignUp(formData) {
        let {username, code} = formData;
        try {
            await Auth.confirmSignUp(username, code);
        } catch (error) {
            console.log('error confirming sign up', error);
        }
    }, async validateCustomSignUp(formData) {
        if (!checkIfStringIsAlphaNumeric(formData.preferred_username)) {
            return {
                preferred_username: "Display name cannot contain special characters or spaces"
            }
        }
        if (formData.preferred_username.length > 50) return {
            preferred_username: "Display name cannot be longer than 50 characters"
        }
    }, async handleSignUp(formData) {
        let {username, password, attributes} = formData;

        console.log(attributes);
        if (attributes['custom:OptIntoMailingList'] === "false") {
            attributes['custom:OptIntoMailingList'] = "0";
        } else {
            attributes['custom:OptIntoMailingList'] = "1";
        }
        try {
            return Auth.signUp({
                username, password, attributes, autoSignIn: {
                    enabled: true
                }
            });
        } catch (error) {
            console.log('error signing up:', error);
        }
    },
}

export function ConfirmationScreen() {

    const [params] = useSearchParams();
    const [confirmation, setConfirmation] = useState();
    const [errorMessage, setErrorMessage] = useState();

    useEffect(() => {
        checkIfLoggedIn()
    }, [])

    async function checkIfLoggedIn() {
        try {
            let response = await Auth.currentAuthenticatedUser();
            if (response) {
                setConfirmation(false);
                setErrorMessage({
                    message: "You are already logged in, please sign out to confirm another account"
                });
            }
        } catch (error) {
            confirmUser();
            return false;
        }
    }

    async function confirmUser() {
        try {
            let response = await Auth.confirmSignUp(params.get("username"), params.get("code"));
            if (response) {
                setConfirmation(true);
            }
        } catch (error) {
            setErrorMessage(error);
        }
    }

    function DisplayWindow({children}) {
        return <Box
            className={"list-card-container active-card-container"}
            flexDirection={"column"}
            alignItems={"center"}
            maxWidth={"400px"}>
            {children}
        </Box>
    }

    function ConfirmedAccount() {

        const dispatch = useDispatch();
        dispatch(setRedirectionPage("survey"))

        function ConfirmedUser() {
            return (<>
                <Typography
                    display={"block"}
                    variant={"body1"}>
                    Your account has been successfully confirmed. Login below
                </Typography>
                <Button
                    color={"secondary"}
                    display={"block"}
                    variant={"contained"}
                    component={RouterLink}
                    to={"/"}>
                    Login HERE
                </Button>
            </>)
        }


        if (confirmation) {
            return <DisplayWindow>
                <ConfirmedUser/>
            </DisplayWindow>
        }

        if (!confirmation && (params.get("username") !== null && errorMessage === undefined)) {
            return <DisplayWindow>
                <CircularProgress/>
                <Button
                    color={"info"}
                    display={"block"}
                    variant={"text"}
                    component={RouterLink}
                    to={"/"}>
                    Home
                </Button>
            </DisplayWindow>
        }

        if (!confirmation && errorMessage) {
            return <DisplayWindow>
                <Typography
                    display={"block"}
                    variant={"body1"}>
                    {errorMessage.message}
                </Typography>
                <Button
                    color={"info"}
                    display={"block"}
                    variant={"text"}
                    component={RouterLink}
                    to={"/"}>
                    Home
                </Button>
            </DisplayWindow>
        }

        if (!confirmation && (params.get("username") === null)) {
            return <DisplayWindow>
                <Typography
                    display={"block"}
                    variant={"body1"}>
                    You have not provided a confirmation code. Please check your email for a confirmation code
                </Typography>
                <Button
                    color={"info"}
                    display={"block"}
                    variant={"text"}
                    component={RouterLink}
                    to={"/"}>
                    Home
                </Button>
            </DisplayWindow>
        }
    }

    return <Box
        className={"centre-box"}
        sx={{
            alignCenter: `center`, alignItems: `center`, display: `flex`, minHeight: `90vh`, flexDirection: `column`,
        }}>
        <ConfirmedAccount/>
    </Box>
}

function AuthenticatorApp({Screen, redirection}) {

    const dispatch = useDispatch();

    const RedirectPage = () => {
        let navigate = useNavigate();

        useEffect(() => {

            if (Screen === "confirmationSuccessful" || Screen === "signUp") {
                dispatch(setRedirectionPage("survey"));
            }

            getLoginInformation();

            return navigate("/")

        }, [])
    }

    useEffect(() => {
        updateBackgroundColour(`#404040`)
        return () => {
            dispatch(setCurrentView(""));
        }
    }, []);

    useEffect(() => {
        if (redirection) {
            dispatch(setRedirectionPage(redirection));
        }
    }, [])

    function TitleDisplay() {
        if (Screen === "signIn") {
            return <Box display={"flex"} width={"100%"} maxWidth={"400px"}>
                <Typography textTransform={"uppercase"} display={"block"}>Login</Typography>
            </Box>
        }
        if (Screen === "signUp") {
            return <Box display={"flex"} width={"100%"} maxWidth={"400px"}>
                <Typography textTransform={"uppercase"} display={"block"}>Register</Typography>
            </Box>
        }
    }

    function DisplayRegistrationBox() {
        if (Screen === "signIn") {
            return <Box className={"list-card-container active-card-container"} flexDirection={"column"}
                        alignItems={"center"} width={"100%"} maxWidth={"400px"}>
                <Typography display={"block"} variant={"body1"}>Don't have an account yet?</Typography>
                <Button color={"info"} display={"block"} variant={"text"} component={RouterLink} to={"/register"}>Register
                    HERE </Button>
            </Box>;
        }
        if (Screen === "signUp") {
            return <Box className={"list-card-container active-card-container"} flexDirection={"column"}
                        alignItems={"center"} width={"100%"} maxWidth={"400px"}>
                <Typography display={"block"} variant={"body1"}>Already Registered with Animotive? </Typography>
                <Button color={"info"} display={"block"} variant={"text"} component={RouterLink} to={"/"}>Login
                    HERE</Button>
            </Box>;
        }
        if (Screen === "confirmationSuccessful") {
            return <Box className={"list-card-container active-card-container"} flexDirection={"column"}
                        alignItems={"center"} maxWidth={"400px"}>
                <Typography display={"block"} variant={"body1"}>Your account has been successfully confirmed. Login
                    below</Typography>
            </Box>;
        }
    }

    const components = {
        SignUp: {
            FormFields() {
                const {validationErrors} = useAuthenticator();
                return (<>
                    <Authenticator.SignUp.FormFields/>
                    <TextField
                        name={"preferred_username"}
                        placeholder={"Enter your display name"}
                        label={"Display Name"}
                        required={true}
                        errorMessage={validationErrors.preferred_username}
                        hasError={!!validationErrors.preferred_username}
                    />
                    <Typography>Stay connected with everything Animotive!</Typography>
                    <Stack direction={"row"} spacing={2}>
                        <RadioGroupField direction={"row"} isRequired={true} name={'custom:OptIntoMailingList'}>
                            <Radio value={'true'}>Yes</Radio>
                            <Radio value={'false'}>No</Radio>
                        </RadioGroupField>
                        <Typography fontStyle={"italic"}>Yes!  I’d like to occasionally receive
                            info, product updates and offers.</Typography>
                    </Stack>
                    <TermsAndConditionsButton/>
                    <PrivacyPolicyButton/>
                </>)
            }
        }, SignIn: {
            FormFields() {
                return (<>
                    <Authenticator.SignIn.FormFields/>
                    <Typography variant="body2" color="text.secondary" align="center">
                        <RouterLink to="/forgot-password">
                            Forgot password?
                        </RouterLink>
                    </Typography>
                </>)
            }
        }
    }

    function DisplayAuthenticator() {
        if (Screen === "signIn") {
            return <Authenticator loginMechanisms={[`email`]} usernameAlias="email" formFields={formFields}
                                  initialState={"signIn"} components={components}
            >
                {({signOut, user}) => (<main>
                    <h1>Hello {user.username}</h1>
                    <button onClick={signOut}>Sign Out</button>
                    <RedirectPage/>
                </main>)}
            </Authenticator>
        }
        if (Screen === "signUp") {
            return <Authenticator loginMechanisms={[`email`]} usernameAlias="email" formFields={formFields}
                                  initialState={"signUp"} services={services}
                                  signUpAttributes={['custom:OptIntoMailingList',]}
                                  components={components}
            >
                {({signOut, user}) => (<main>
                    <h1>Hello {user.username}</h1>
                    <button onClick={signOut}>Sign Out</button>
                    <RedirectPage/>
                </main>)}
            </Authenticator>
        }
    }

    return (<Box
        className={"centre-box"}
        sx={{
            alignCenter: `center`,
            alignItems: `center`,
            display: `flex`,
            flexDirection: `column`,
        }}>
        <TitleDisplay/>
        <DisplayRegistrationBox/>
        <DisplayAuthenticator/>
    </Box>)
}

export default AuthenticatorApp;
