import React from "react";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (<div
        role="tabpanel"
        hidden={value !== index}
        id={`tab-${index}`}
        aria-labelledby={`tabpanel-${index}`}
        {...other}
    >
        {value === index && (<Box>
            <Typography component={'span'}>{children}</Typography>
        </Box>)}
    </div>);
}