import Button from "@mui/material/Button";
import {Box, Link, Stack} from "@mui/material";
import Posts from "../api-calls/Posts";
import Typography from "@mui/material/Typography";
import {Link as RouterLink} from "react-router-dom";
import React from "react";
import {setPrimaryNavigationIndex} from "../features/states/ProjectFunctions";
import {useDispatch} from "react-redux";
import {GetInvitesFromServer} from "../functions/ServerCallFunctions";

export default function InvitePanel(props) {

    const [accept, setAccept] = React.useState(false);

    const invite = props.invite;
    const projectID = invite.projectId;
    const dispatch = useDispatch();

    async function acceptInviteFunc(inviteID, reply) {
        const inviteResponse = {
            inviteID: inviteID,
            inviteStatus: reply
        }
        const response = await Posts(inviteResponse, "UserInvite/accept-invite");
        if (response.code === 200) {
            if (reply === 2) {
                setAccept(true);
            }
            if (reply === 3) {
                GetInvitesFromServer();
            }
        }
    }

    function acceptInvite() {
        acceptInviteFunc(invite.inviteId, 2);
    }

    function declineInvite() {
        acceptInviteFunc(invite.inviteId, 3);
    }

    function InviteDisplayer() {
        return (<Stack direction={`row`} sx={{width: "100%"}}>
            <Box className={"list-card-container"} sx={{justifyContent:"space-between", alignItems:"center"}}>
                {invite.projectTitle}
                <Box>
                <Button sx={{marginRight:1}} variant={`contained`} color={"secondary"} onClick={acceptInvite}>Accept</Button>
                <Button variant={`contained`} color={"secondary"} onClick={declineInvite}>Decline</Button>
                </Box>
            </Box>
        </Stack>)
    }

    function AcceptedInviteDisplay() {
        const route = `/projects/${projectID}`
        const link = <Link state={{ProjectTitle: invite.projectTitle, id: projectID}} component={RouterLink} onClick={() => dispatch(setPrimaryNavigationIndex(1))
        } to={route}>{invite.projectTitle}</Link>

        return <Box>
            <Typography>You have accepted the invite to {link}</Typography>
        </Box>
    }

    return accept ? <AcceptedInviteDisplay/> : <InviteDisplayer/>
}