import SceneSelectionCollapsable from "../components/SceneSelectionCollapsable";
import {useEffect, useState} from "react";
import {Box, Button, CircularProgress, Divider, Stack} from "@mui/material";
import ConfirmationDialogWindow from "../components/ConfirmationDialogWindow";
import React from "react";
import CreateSceneFormComponent from "../components/Form Components/CreateSceneFormComponent";
import {projectSortOptions, SortProjectsByMethod} from "../functions/DataSortingFunctions";
import Posts from "../api-calls/Posts";
import {selectCurrentProject, selectScenes, selectUnity} from "../features/states/ProjectFunctions";
import {useSelector} from "react-redux";
import DeleteAPI from "../api-calls/Delete";
import {Link as RouterLink, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import {GetScenesFromServer} from "../functions/ServerCallFunctions";
import SortListButton from "../components/Structural Components/SortListButton";
import ErrorModal from "../components/Structural Components/ErrorModal";


function SceneListDisplayPage() {

    let {id} = useParams();
    const openInUnity = useSelector(selectUnity);

    const [sortByIndex, setSortByIndex] = useState(3);
    const [loadingScenes, setLoadingScenes] = useState(false);

    let currentProject = useSelector(selectCurrentProject)
    const scenesInProject = useSelector(selectScenes)

    const handleSortOptionChange = () => {
        if (sortByIndex < 5) {
            setSortByIndex(sortByIndex + 1);
        } else {
            setSortByIndex(0);
        }
    };

    useEffect(() => {
        GetScenes();
    }, [currentProject]);

    async function GetScenes() {
        if (currentProject.projectId === id || scenesInProject === null) {
            setLoadingScenes(true);
            const response = await GetScenesFromServer(id);
            if (response === null) {
                setLoadingScenes(false);
            }
        }
    }

    function InformationBox() {
        return <Stack className={"list-card-container"} textOverflow={"wrap"} textAlign={"center"}>
            <Typography>
                Scenes can only be opened on the Microsoft
                Windows operating system with the
                Animotive application installed.
            </Typography>
            <Button
                variant={"contained"}
                color={"secondary"}
                sx={{marginTop: `1em`}}
                component={RouterLink} to={"/launch-animotive"}
            >
                Launch Animotive
            </Button>
        </Stack>;
    }

    function VrNotificationBox() {
        return <Stack className={"list-card-container active-card-container"} textOverflow={"wrap"}
                      textAlign={"center"} sx={{marginTop: `1em`}}>
            <Typography color={"#99d4ff"}>
                If you intend to experience this scene in VR, please
                ensure you have a valid headset connection before
                proceeding
            </Typography>
        </Stack>;
    }

    function CreateSceneButtonComponent() {
        const isAdmin = useSelector(selectCurrentProject).isAdmin;

        if (isAdmin) {
            return <Box sx={{display: 'flex', flexGrow: 1, flexDirection: `row`, justifyContent: 'flex-start'}}>
                <CreateSceneFormComponent
                    projectID={currentProject.projectId}
                    scenesInProject={scenesInProject}
                />
            </Box>;
        }
    }

    return <Stack className="scene-buttons" spacing={1}>
        <SortListButton
            callback={handleSortOptionChange}
            sortByIndex={sortByIndex}
        />
        <CreateSceneButtonComponent/>
        {!openInUnity ? <InformationBox/> : <VrNotificationBox/>}
        <SceneListDisplayer
            loadingScenes={loadingScenes}
            scenesInProject={scenesInProject}
            setLoadingScenes={setLoadingScenes}
            sortByIndex={sortByIndex}
        />
    </Stack>
}

function SceneListDisplayer({scenesInProject, ...props}) {

    let {id} = useParams();

    const [sortedScenes, setSortedScenes] = useState();
    const [errorModalSettings, setErrorModalSettings] = useState({open: false, title: "", content: ""});

    const [open, setOpen] = React.useState(false);
    const [deleteGUID, setDeleteGUID] = useState(null);

    const currentProject = useSelector(selectCurrentProject);

    useEffect(() => {
        if (scenesInProject !== undefined && scenesInProject !== null) {
            const newData = [...scenesInProject];
            SortProjectsByMethod(newData, projectSortOptions[props.sortByIndex].value, "sceneName");
            setSortedScenes(newData);
        }
    }, [scenesInProject, props.sortByIndex]);

    useEffect(() => {
        if (sortedScenes !== undefined) {
            props.setLoadingScenes(false);
        }
    }, [sortedScenes]);

    if (props.loadingScenes) {
        return <CircularProgress/>
    }

    const handleDeleteWarning = (e) => {
        setOpen(true);
        setDeleteGUID(e);
    }

    async function handleDeleteScene(GUID) {
        const deleteStruct = {
            sceneKey: GUID,
            projectId: currentProject.projectId
        };

        let response = await DeleteAPI(deleteStruct, "Scenes/delete-project-scene")
        if (response.code === 200) {
            GetScenesFromServer(id);
        }
    }

    const handleClose = (e, callback) => {
        setOpen(false);
        if (e === true) {
            callback(deleteGUID);
        }
        setDeleteGUID(null);
    };

    function handleErrorClose() {
        setErrorModalSettings({open: false, title: "", content: ""});
    }

    async function handleSceneValueUpdate(guid, newName) {
        const SceneUpdate = {
            sceneKey: guid,
            sceneName: newName,
            projectId: currentProject.projectId
        };

        let response = await Posts(SceneUpdate, "Scenes/update-project-scene-name")

        if (response.code === 200) {
            GetScenesFromServer(id);
        }
    }

    async function duplicateScenes(guid) {
        const sceneData = {sceneId: guid}
        const response = await Posts(sceneData, `Scenes/copy-scene`)
        if(response.code === 200) {
            GetScenesFromServer(id)
        }
        else
        {
            setErrorModalSettings({
                open: true,
                title: "Scene Copy Error",
                content: "There was an error copying the scene. Your storage may be full."
            })
        }


    }

    if (scenesInProject === null || scenesInProject === undefined) {
        return <Box sx={{flexDirection: `column`, display: 'flex'}}>
            <Typography>
                No scenes found
            </Typography>
        </Box>
    }

    if (sortedScenes !== undefined) {
        return <Box sx={{flexDirection: `column`, display: 'flex'}}>
            {sortedScenes.map((scene, index) => {
                return (
                    <Box key={scene.sceneKey}>
                        <SceneSelectionCollapsable
                            index={index}
                            field={scene}
                            projectID={id}
                            openMenu={handleDeleteWarning}
                            errorModal={setErrorModalSettings}
                            callback={handleSceneValueUpdate}
                            duplicateCallback={duplicateScenes}
                            isAdmin={currentProject.isAdmin}
                        />
                        <Divider light/>
                    </Box>
                )
            })
            }
            <ConfirmationDialogWindow
                open={open}
                onClose={handleClose}
                callback={handleDeleteScene}
            />
            <ErrorModal settings={errorModalSettings} closeCallback={handleErrorClose}/>
        </Box>
    }
}

export default SceneListDisplayPage;